import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';

import { CreditCard } from '../interfaces/CreditCard';
import { EmailAddress } from '../interfaces/EmailAddress';
import { PhoneNumber } from '../interfaces/PhoneNumber';
import { User } from '../interfaces/User';
import { AlertData, AlertType } from './../interfaces/AlertData';
import { BankAccount } from './../interfaces/BankAccount';
import { API } from './API';

export default class IdSchutzManager {
  static async getUser(): Promise<User> {
    const user: User = await API.get(`users/me`);
    const token = localStorage.getItem('token');

    if ((!user.firstName || !user.lastName || !user.id) && token) {
      const decoded: any = jwtDecode(token);

      user.firstName = decoded.userInfo.firstName;
      user.lastName = decoded.userInfo.lastName;
      user.id = decoded.userInfo.id;

      this.updateUserData({
        firstName: decoded.userInfo.firstName,
        lastName: decoded.userInfo.lastName,
        id: decoded.userInfo.id,
      });
    }

    return user;
  }

  static async updateUserData(data: any) {
    return API.patch(`users/me`, data);
  }

  static async setUserUpdated(data: any) {
    return API.patch(`user/me`, data);
  }

  static getCreateObject(key: any, value: any) {
    const keyObjectMapping: any = {
      emailAddresses: { email: value },
      bankAccounts: { iban: value },
      phoneNumbers: { number: value },
      creditCards: { number: value },
    };
    return keyObjectMapping[key];
  }

  static getLabel(key: any, singular: boolean = false): string {
    const keyLabelMap: any = {
      emailAddresses: {
        plural: 'E-Mail Adressen',
        singular: 'E-Mail Adresse',
      },
      bankAccounts: { plural: 'IBANs', singular: 'IBAN' },
      phoneNumbers: {
        plural: 'Telefonnummern',
        singular: 'Telefonnummer',
      },
      creditCards: {
        plural: 'Kreditkarten',
        singular: 'Kreditkarte',
      },
      driversLicenseNumber: {
        plural: 'Führer&shy;schein&shy;nummer',
        singular: 'Führer&shy;schein&shy;nummer',
      },
      nationalIdNumber: {
        plural: 'Personal&shy;ausweis&shy;nummer',
        singular: 'Personal&shy;ausweis&shy;nummer',
      },
      passportNumber: {
        plural: 'Reisepass&shy;nummer',
        singular: 'Reisepass&shy;nummer',
      },
    };
    return singular ? keyLabelMap[key].singular : keyLabelMap[key].plural;
  }

  static getAlertTypeLabel(key: AlertType) {
    switch (key) {
      case 'bankAccount':
        return 'Bankkonto';
      case 'iban':
        return 'IBAN';
      case 'creditCard':
        return 'Kreditkarte';
      case 'email':
        return 'E-Mail Adresse';
      case 'phone':
        return 'Telefonnummer';
      case 'passportNumber':
        return 'Reisepass';
      case 'driversLicenseNumber':
        return 'Führerschein';
      case 'firstName':
        return 'Vorname';
      case 'lastName':
        return 'Nachname';
      case 'dayOfBirth':
        return 'Geburtstag';
      case 'nationalIdNumber':
        return 'Personalausweis';
    }
  }

  static getMaximumForInputType(key: any) {
    const keyMaximumMap: any = {
      emailAddresses: 11,
      bankAccounts: 10,
      phoneNumbers: 9,
      creditCards: 10,
      driversLicenseNumber: 1,
      passportNumber: 1,
      nationalIdNumber: 1,
    };
    return keyMaximumMap[key];
  }

  static async getFormData<T>(type: 'phoneNumbers'): Promise<PhoneNumber[]>;
  static async getFormData<T>(type: 'emailAddresses'): Promise<EmailAddress[]>;
  static async getFormData<T>(type: 'creditCards'): Promise<CreditCard[]>;
  static async getFormData<T>(type: 'bankAccounts'): Promise<BankAccount[]>;
  static async getFormData<T>(type: string): Promise<T[]> {
    return API.get(type);
  }

  static async updateFormData<T>(
    type: 'phoneNumbers',
    id: any,
    data: any
  ): Promise<PhoneNumber[]>;
  static async updateFormData<T>(
    type: 'emailAddresses',
    id: any,
    data: any
  ): Promise<EmailAddress[]>;
  static async updateFormData<T>(
    type: 'creditCards',
    id: any,
    data: any
  ): Promise<CreditCard[]>;
  static async updateFormData<T>(
    type: 'bankAccounts',
    id: any,
    data: any
  ): Promise<BankAccount[]>;
  static async updateFormData<T>(
    type: string,
    id: any,
    data: any
  ): Promise<T[]> {
    // await this.setUserUpdated({ updated: true });

    return API.patch(type + '/' + id, data);
  }

  static async createFormData<T>(
    type: 'phoneNumbers',
    data: any
  ): Promise<PhoneNumber[]>;
  static async createFormData<T>(
    type: 'emailAddresses',
    data: any
  ): Promise<EmailAddress[]>;
  static async createFormData<T>(
    type: 'creditCards',
    data: any
  ): Promise<CreditCard[]>;
  static async createFormData<T>(
    type: 'bankAccounts',
    data: any
  ): Promise<BankAccount[]>;
  static async createFormData<T>(type: string, data: any): Promise<T[]> {
    // await this.setUserUpdated({ updated: true });

    return API.post(type, data, false);
  }

  static async deleteFormData<T>(
    type: 'phoneNumbers',
    id: any
  ): Promise<PhoneNumber[]>;
  static async deleteFormData<T>(
    type: 'emailAddresses',
    id: any
  ): Promise<EmailAddress[]>;
  static async deleteFormData<T>(
    type: 'creditCards',
    id: any
  ): Promise<CreditCard[]>;
  static async deleteFormData<T>(
    type: 'bankAccounts',
    id: any
  ): Promise<BankAccount[]>;
  static async deleteFormData<T>(type: string, id: any): Promise<T[]> {
    return API.delete(type + '/' + id);
  }

  static async getAlerts<T>(): Promise<AlertData[]> {
    return API.get('alerts');
  }
}
