import "./Footer.scss";

import { Container, Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <Container maxWidth="md">
        <Grid container justify="space-between" alignItems="center">
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 28,
              flexDirection: "column",
            }}
          >
            <img
              src="assets/logo_mehrwerk_white.svg"
              alt=""
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item>
            <NavLink to="/agb">Allgemeine Geschäftsbedingungen</NavLink>
            <NavLink to="/datenschutz">Datenschutz</NavLink>
            <NavLink to="/impressum">Impressum</NavLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
