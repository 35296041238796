import React from "react";
import { Collapse } from "@material-ui/core";
import "./ExpandMore.scss";

export default function ExpandMore({
  textExpanded,
  textNotExpanded,
  textPosition = "bottom",
  children,
  style,
}: {
  textExpanded?: any;
  textNotExpanded?: any;
  textPosition?: "top" | "bottom";
  children: any;
  style?: any;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <div className="ExpandMore" style={{ ...style }}>
      {textPosition === "top" && (
        <div className="showMore" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded
            ? textExpanded || "weniger anzeigen"
            : textNotExpanded || "mehr anzeigen"}
        </div>
      )}
      <Collapse in={isExpanded}>{children}</Collapse>
      {textPosition === "bottom" && (
        <div className="showMore" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded
            ? textExpanded || "weniger anzeigen"
            : textNotExpanded || "mehr anzeigen"}
        </div>
      )}
    </div>
  );
}
