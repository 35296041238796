export default class Config {
  static getEnv(name: string) {
    const win = window as any;
    if (win.env && win.env[name] !== undefined) {
      return win.env[name];
    } else {
      return process.env[name];
    }
  }

  static getCreditCardURL(): string {
    return Config.getEnv("REACT_APP_CREADITCARD_URL");
  }

  static getAPIURL(): string {
    return Config.getEnv("REACT_APP_API_URL");
  }

  static getIAMURL(): string {
    return Config.getEnv("REACT_APP_IAM_URL");
  }

  static getApiKey(): string {
    return Config.getEnv("REACT_APP_API_KEY");
  }
}
