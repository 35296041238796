import './Home.scss';

import {
  faArrowCircleDown,
  faArrowCircleRight,
  faArrowLeft,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Tab,
  Tabs,
} from '@material-ui/core';
import Chart from 'chart.js';
import * as React from 'react';

import EditUserDataAccordion from '../../components/EditUserDataAccordion/EditUserDataAccordion';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { AlertData, AlertType } from '../../interfaces/AlertData';
import { CreditCard } from '../../interfaces/CreditCard';
import { EmailAddress } from '../../interfaces/EmailAddress';
import { PhoneNumber } from '../../interfaces/PhoneNumber';
import { User } from '../../interfaces/User';
import IdSchutzManager from '../../services/idschutz.manager';
import Accordion from './../../components/Accordion/Accordion';
import EditDataAccordion from './../../components/EditDataAccordion/EditDataAccordion';
import ExpandMore from './../../components/ExpandMore/ExpandMore';
import LogoutInfo from './../../components/LogoutInfo/LogoutInfo';
import ResponsiveTable from './../../components/ResponsiveTable/ResponsiveTable';
import { BankAccount } from './../../interfaces/BankAccount';
import { FormItem } from './../../interfaces/FormItem';
import UserManager from '../../services/UserManager';

interface Props {}

interface State {
  loading: boolean;
  tabActiveIndex: number;
  isEditDataActive: boolean;
  user?: User;
  formData?: {
    emailAddresses: EmailAddress[];
    phoneNumbers: PhoneNumber[];
    bankAccounts: BankAccount[];
    creditCards: CreditCard[];
  };
  alerts: AlertData[];
}

export class Home extends React.Component<Props, State> {
  formItemKeys = [
    'emailAddresses',
    'bankAccounts',
    'phoneNumbers',
    'creditCards',
  ];

  statusMap: any = {
    schwach: 'low',
    mittel: 'medium',
    hoch: 'high',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      tabActiveIndex: 0,
      isEditDataActive: false,
      alerts: [],
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  calculateRisk(type: 'general' | 'personal' | 'financial'): number[] {
    let risk = 2;
    if (type === 'general') {
      risk +=
        4 *
        this.state.alerts.filter(
          (alert) =>
            alert.type === AlertType.driversLicenseNumber ||
            alert.type === AlertType.email ||
            alert.type === AlertType.phone
        ).length;
      risk +=
        8 *
        this.state.alerts.filter(
          (alert) =>
            alert.type === AlertType.passportNumber ||
            alert.type === AlertType.nationalIdNumber ||
            alert.type === AlertType.bankAccount ||
            alert.type === AlertType.creditCard ||
            alert.type === AlertType.iban
        ).length;
    } else if (type === 'personal') {
      risk +=
        4 *
        this.state.alerts.filter(
          (alert) =>
            alert.type === AlertType.driversLicenseNumber ||
            alert.type === AlertType.email ||
            alert.type === AlertType.phone
        ).length;

      risk +=
        8 *
        this.state.alerts.filter(
          (alert) =>
            alert.type === AlertType.nationalIdNumber ||
            alert.type === AlertType.passportNumber
        ).length;
    } else if (type === 'financial') {
      risk +=
        8 *
        this.state.alerts.filter(
          (alert) =>
            alert.type === AlertType.bankAccount ||
            alert.type === AlertType.creditCard ||
            alert.type === AlertType.iban
        ).length;
    }
    return [risk, 18 - risk > 0 ? 18 - risk : 0];
  }

  calculateRiskStatus(type: 'general' | 'personal' | 'financial'): string {
    let status = 'schwach';
    let risk = this.calculateRisk(type)[0];

    if (risk > 2 && risk < 10) {
      status = 'mittel';
    } else if (risk >= 10) {
      status = 'hoch';
    }
    return status;
  }

  calculateRiskColor(type: 'general' | 'personal' | 'financial'): string {
    let color = '#37c391';
    let risk = this.calculateRisk(type)[0];

    if (risk > 2 && risk < 10) {
      color = '#ffd719';
    } else if (risk >= 10) {
      color = '#eb4b50';
    }
    return color;
  }

  async componentDidUpdate() {
    if (!this.state.loading) {
      const elementGeneralRiskChart = document.getElementById(
        'riskChartGeneral'
      ) as HTMLCanvasElement;
      const elementPersonalRiskChart = document.getElementById(
        'riskChartPersonal'
      ) as HTMLCanvasElement;
      const elementFinancialRiskChart = document.getElementById(
        'riskChartFinancial'
      ) as HTMLCanvasElement;
      const chartOptions: any = {
        legend: false,
        rotation: 1.57,
        cutoutPercentage: 66,
        tooltips: false,
      };
      let draw = Chart.controllers.doughnut.prototype.draw;
      Chart.controllers.doughnut = Chart.controllers.doughnut.extend({
        draw: function() {
          let ctx: CanvasRenderingContext2D = this.chart.chart.ctx;
          ctx.closePath();
          ctx.beginPath();
          ctx.arc(
            this.chart.canvas.clientWidth / 2,
            this.chart.canvas.clientHeight / 2,
            this.chart.canvas.clientHeight / 2 -
              (this.chart.canvas.clientHeight / 2) * 0.17,
            0,
            2 * Math.PI
          );
          ctx.strokeStyle = '#ccc';
          ctx.lineWidth = this.chart.canvas.clientHeight * 0.095;
          ctx.stroke();
          draw.apply(this, arguments);
          ctx.closePath();
        },
      });
      new Chart(elementGeneralRiskChart, {
        type: 'doughnut',
        data: {
          labels: ['Risiko', 'Restrisiko'],
          datasets: [
            {
              label: 'Risiko',
              data: this.calculateRisk('general'),
              backgroundColor: [
                this.calculateRiskColor('general'),
                'transparent',
              ],
              borderWidth: [0, 0],
            },
          ],
        },
        options: chartOptions,
      });
      new Chart(elementPersonalRiskChart, {
        type: 'doughnut',
        data: {
          labels: ['Risiko', 'Restrisiko'],
          datasets: [
            {
              label: 'Risiko',
              data: this.calculateRisk('personal'),
              backgroundColor: [
                this.calculateRiskColor('personal'),
                'transparent',
              ],
              borderWidth: [0, 0],
            },
          ],
        },
        options: chartOptions,
      });
      new Chart(elementFinancialRiskChart, {
        type: 'doughnut',
        data: {
          labels: ['Risiko', 'Restrisiko'],
          datasets: [
            {
              label: 'Risiko',
              data: this.calculateRisk('financial'),
              backgroundColor: [
                this.calculateRiskColor('financial'),
                'transparent',
              ],
              borderWidth: [0, 0],
            },
          ],
        },
        options: chartOptions,
      });
    }
  }

  async asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async componentDidMount() {
    const formData: any = {};
    const user = await IdSchutzManager.getUser();
    let alerts = await IdSchutzManager.getAlerts();
    await this.asyncForEach(this.formItemKeys, async (element: any) => {
      formData[element] = await IdSchutzManager.getFormData(element);
    });

    if (user.firstName === 'Max' && user.lastName === 'Mustermann') {
      alerts = [
        {
          type: AlertType.email,
          value: 'test@test.de',
          date: '2020-05-19T13:56:45.202Z',
        },
        {
          type: AlertType.bankAccount,
          value: 'DE0123456789',
          date: '2020-05-18T13:56:45.202Z',
        },
      ];
    }

    this.setState({
      loading: false,
      user,
      formData,
      alerts,
    });
  }

  handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
    this.jumpToResults(undefined);
    this.setState({ tabActiveIndex: newValue });
  }

  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      classNames: 'textColorPrimary',
    };
  }

  jumpToResults(newTabIndex: number = 0) {
    const resultsElement = document.getElementById('results');
    const top =
      (resultsElement?.getBoundingClientRect().top || 0) + window.scrollY;
    if (newTabIndex >= 0) {
      this.setState({ tabActiveIndex: newTabIndex }, () =>
        window.scrollTo({ top: top, left: 0, behavior: 'smooth' })
      );
    } else {
      window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
    }
  }

  countAlertsForType(
    type:
      | 'emailAddresses'
      | 'bankAccounts'
      | 'phoneNumbers'
      | 'creditCards'
      | 'driversLicenseNumber'
      | 'nationalIdNumber'
      | 'passportNumber'
  ): number {
    let count = 0;
    if (this.state.alerts) {
      this.state.alerts.forEach((alert) => {
        if (
          (type === 'emailAddresses' && alert.type === AlertType.email) ||
          (type === 'bankAccounts' && alert.type === AlertType.bankAccount) ||
          (type === 'phoneNumbers' && alert.type === AlertType.phone) ||
          (type === 'driversLicenseNumber' &&
            alert.type === AlertType.driversLicenseNumber) ||
          (type === 'nationalIdNumber' &&
            alert.type === AlertType.nationalIdNumber) ||
          (type === 'passportNumber' &&
            alert.type === AlertType.passportNumber) ||
          (type === 'creditCards' && alert.type === AlertType.creditCard)
        ) {
          count++;
        }
      });
    }
    return count;
  }

  generateTableData(): any[] {
    const data: any[] = [];
    this.state.alerts.forEach((alert) => {
      data.push([
        IdSchutzManager.getAlertTypeLabel(alert.type),
        alert.value,
        new Date(alert.date).toLocaleDateString('de-DE'),
        'HOCH',
      ]);
    });
    return data;
  }

  render() {
    return (
      <Layout
        menuItems={[
          <MenuItem onClick={() => this.jumpToResults()}>
            Ihre Ergebnisse
          </MenuItem>,
          <MenuItem onClick={() => this.jumpToResults(1)}>Ihre Daten</MenuItem>,
          <MenuItem onClick={() => this.jumpToResults(2)}>
            Tipps & Tricks
          </MenuItem>,
          <MenuItem onClick={() => this.jumpToResults(3)}>
            Fragen & Antworten
          </MenuItem>,
        ]}
      >
        <div className="Home">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}
          {!this.state.loading && (
            <div>
              <Container maxWidth="md" style={{ position: 'relative' }}>
                <LogoutInfo
                  firstName={this.state.user?.firstName}
                  lastName={this.state.user?.lastName}
                  id={this.state.user?.id}
                />
              </Container>
              <InnerLayout>
                <div className="contentBox contentBox--transparent">
                  <Grid container>
                    <Grid xs={12} sm={8} item>
                      <h2>
                        Sch&uuml;tzen Sie Ihre Identit&auml;t im Internet!
                      </h2>
                      <p>
                        In der heutigen Zeit ist das Internet nicht mehr
                        wegzudenken. Wir machen fast alles &uuml;ber das
                        Internet. Jedoch muss man bei einigen
                        Netzaktivit&auml;ten Acht geben, dass die
                        pers&ouml;nlichen Daten nicht in die in falschen
                        H&auml;nden geraten und gro&szlig;en Schaden anrichten!
                      </p>
                      <p>
                        Wir bieten Ihnen daher einen Service, der Sie vor
                        Missbrauch im Internet warnt.
                      </p>
                      <h2>So funktioniert&acute;s:</h2>
                      <ul>
                        <li>
                          <strong>Online-Monitor</strong> &uuml;berwacht Ihre
                          Daten im Internet
                        </li>
                        <li>
                          <strong>Warnungen</strong> bei Verdacht von
                          Datenmissbrauch (per E-Mail oder SMS)
                        </li>
                        <li>
                          <strong>Service-Hotline:</strong> 0521 7000-219
                        </li>
                      </ul>
                      <h6>
                        * Hinweis: Eine Rechtsberatung wird nicht vorgenommen.
                      </h6>
                      <p>
                        Der Identit&auml;tsschutz ist eine Plattform zur Eingabe
                        der personenbezogenen Daten und bietet ein
                        Data-Screening sowie eine Support-Hotline f&uuml;r
                        R&uuml;ckfragen zu den Leistungen oder um einen
                        Identit&auml;tsdiebstahl zu melden!
                      </p>
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.jumpToResults()}
                  >
                    <span>Ihre Ergebnisse</span>
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                  </Button>
                </div>
                <div style={{ marginTop: 80 }}></div>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <div
                      className="contentBox"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <h3>Risikoniveau</h3>
                      <Grid container>
                        <Grid item xs={6}>
                          <canvas
                            id="riskChartGeneral"
                            width="100"
                            height="100"
                          ></canvas>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="chartLegend">
                            <div className="legendItem low">schwach</div>
                            <div className="legendItem medium">mittel</div>
                            <div className="legendItem high">hoch</div>
                          </div>
                        </Grid>
                      </Grid>
                      <p style={{ width: '100%' }}>
                        Ihr Risikoniveau wurde als{' '}
                        {this.calculateRiskStatus('general')} eingeschätzt.
                        Bitte überprüfen Sie Ihre Ergebnisse.
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.jumpToResults()}
                      >
                        <span>Ihre Ergebnisse</span>
                        <FontAwesomeIcon icon={faArrowCircleDown} />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div
                      className="contentBox"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <h3>Überwachung Ihrer Daten</h3>
                      <table className="statusList" style={{ width: '100%' }}>
                        <tbody>
                          {this.state.formData &&
                            [
                              ...Object.entries(this.state.formData),
                              ...Object.entries({
                                driversLicenseNumber: this.state.user
                                  ?.driversLicenseNumber,
                                nationalIdNumber: this.state.user
                                  ?.nationalIdNumber,
                                passportNumber: this.state.user?.passportNumber,
                              }),
                            ].map(([key, value]) => (
                              <tr>
                                <td>
                                  <FontAwesomeIcon
                                    color={
                                      this.countAlertsForType(key as any) > 0
                                        ? '#eb4b50'
                                        : !value || value.length <= 0
                                        ? '#aaa'
                                        : '#37c391'
                                    }
                                    icon={faCheckCircle}
                                    style={{ marginRight: 12 }}
                                  />{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: IdSchutzManager.getLabel(key),
                                    }}
                                  ></span>
                                  {(typeof value === 'object' &&
                                    value &&
                                    ` ${
                                      value.length
                                    }/${IdSchutzManager.getMaximumForInputType(
                                      key
                                    )}`) ||
                                    ` ${
                                      value ? '1' : '0'
                                    }/${IdSchutzManager.getMaximumForInputType(
                                      key
                                    )}`}
                                </td>
                                <td>
                                  <FontAwesomeIcon
                                    color={
                                      this.countAlertsForType(key as any) > 0
                                        ? '#eb4b50'
                                        : '#aaa'
                                    }
                                    icon={faExclamationTriangle}
                                    style={{ marginRight: 12 }}
                                  />{' '}
                                  <small>
                                    {value && value.length > 0
                                      ? 'Findings: ' +
                                        this.countAlertsForType(key as any)
                                      : 'Nicht hinterlegt'}
                                  </small>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.jumpToResults(1)}
                        style={{ marginTop: 8 }}
                      >
                        <span>Ihre Daten</span>
                        <FontAwesomeIcon icon={faArrowCircleDown} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 40 }}>
                  <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <div className="contentBox" style={{ padding: '0' }}>
                      <Tabs
                        id="results"
                        value={this.state.tabActiveIndex}
                        onChange={this.handleChangeTab}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label="Ergebnisübersicht" {...this.a11yProps(0)} />
                        <Tab label="Ihre Daten" {...this.a11yProps(1)} />
                        <Tab label="Tipps & Tricks" {...this.a11yProps(2)} />
                        <Tab
                          label="Fragen & Antworten"
                          {...this.a11yProps(3)}
                        />
                      </Tabs>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      role="tabpanel"
                      hidden={this.state.tabActiveIndex !== 0}
                      id="simple-tabpanel-0"
                      aria-labelledby="simple-tab-0"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="contentBox contentBox--transparent">
                            <h3>
                              Übersicht der Suchergebnisse Ihrer eingegebenen
                              Daten
                            </h3>

                            <p>
                              Behalten Sie Ihre pers&ouml;nlichen Daten, die im
                              Internet gefunden wurden, stets im Auge. So
                              stellen Sie sicher, dass Ihre Daten nicht ohne Ihr
                              Wissen verwendet werden. Die zwei Kreisdiagramme
                              unten spiegeln Ihre pers&ouml;nlichen und
                              finanziellen Risiken wider. Sie finden die
                              einzelnen Ergebnisse der Suche unten auf der Seite
                              unter den Kreisdiagrammen. Sollte dort eine URL
                              aufgef&uuml;hrt sein, k&ouml;nnen Sie diese
                              aufrufen um die Website zu besuchen, auf der Ihre
                              pers&ouml;nlichen Daten gefunden wurden.
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="contentBox">
                            <h4>Persönlich</h4>
                            <div className="chartContainer">
                              <canvas
                                id="riskChartPersonal"
                                width="150"
                                height="150"
                              ></canvas>
                              <div className="chartLegend chartLegend--absolute">
                                <div
                                  className={
                                    'legendItem ' +
                                    this.statusMap[
                                      this.calculateRiskStatus(
                                        'personal'
                                      ) as any
                                    ]
                                  }
                                  style={{ minWidth: 'auto' }}
                                >
                                  {this.calculateRiskStatus('personal')}
                                </div>
                              </div>
                            </div>
                            <p>
                              Dies spiegelt Risiken hinsichtlich Ihrer
                              registrierten persönlichen Informationen wider,
                              z.B. Ihre E-Mail-Adresse.
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="contentBox">
                            <h4>Finanziell</h4>
                            <div className="chartContainer">
                              <canvas
                                id="riskChartFinancial"
                                width="100"
                                height="100"
                              ></canvas>
                              <div className="chartLegend chartLegend--absolute">
                                <div
                                  className={
                                    'legendItem ' +
                                    this.statusMap[
                                      this.calculateRiskStatus('financial')
                                    ]
                                  }
                                  style={{ minWidth: 'auto' }}
                                >
                                  {this.calculateRiskStatus('financial')}
                                </div>
                              </div>
                            </div>
                            <p>
                              Dies spiegelt Risiken hinsichtlich Ihrer
                              registrierten finanziellen Informationen wider,
                              z.B. Ihre Kreditkartennummer.
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 40 }}>
                          <div className="contentBox">
                            <ResponsiveTable
                              title=""
                              columns={[
                                'Gefunden',
                                'Übereinstimmung',
                                'Datum',
                                'Risiko',
                              ]}
                              data={this.generateTableData()}
                              options={{ elevation: 0 }}
                            ></ResponsiveTable>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={this.state.tabActiveIndex !== 1}
                      id="simple-tabpanel-1"
                      aria-labelledby="simple-tab-1"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            hidden={this.state.isEditDataActive}
                            className="contentBox"
                          >
                            <h3>Ihre Daten</h3>
                            <p>
                              Hier können Sie Ihre personenbezogenen Daten zur
                              Suche im Internet und Dark Net hinterlegen und die
                              von Ihnen bereits hinterlegten Daten einsehen und
                              bearbeiten. Je mehr Daten Sie von sich überprüfen
                              lassen, desto sicherer sind sie.
                            </p>
                            <table
                              className="statusList"
                              style={{ width: '100%' }}
                            >
                              <tbody>
                                {this.state.formData &&
                                  [
                                    ...Object.entries(this.state.formData),
                                    ...Object.entries({
                                      driversLicenseNumber: this.state.user
                                        ?.driversLicenseNumber,
                                      nationalIdNumber: this.state.user
                                        ?.nationalIdNumber,
                                      passportNumber: this.state.user
                                        ?.passportNumber,
                                    }),
                                  ].map(([key, items]) => (
                                    <tr>
                                      <td>
                                        {' '}
                                        <FontAwesomeIcon
                                          color={
                                            this.countAlertsForType(
                                              key as any
                                            ) > 0
                                              ? '#eb4b50'
                                              : !items || items.length <= 0
                                              ? '#aaa'
                                              : '#37c391'
                                          }
                                          icon={faCheckCircle}
                                          style={{ marginRight: 12 }}
                                        />{' '}
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: IdSchutzManager.getLabel(
                                              key
                                            ),
                                          }}
                                        ></span>
                                      </td>
                                      <td>
                                        <div className="itemList">
                                          <div>
                                            {(items &&
                                              typeof items === 'object' &&
                                              items[0] &&
                                              ((items[0] as any).iban ||
                                                (items[0] as any).email ||
                                                (items[0] as any).number)) ||
                                              items}
                                          </div>
                                          {items &&
                                            typeof items === 'object' &&
                                            items.length > 1 && (
                                              <ExpandMore
                                                style={{
                                                  marginTop: '-2.25em',
                                                  textAlign: 'right',
                                                }}
                                                textPosition="top"
                                                textExpanded={
                                                  <FontAwesomeIcon
                                                    icon={faChevronUp}
                                                  />
                                                }
                                                textNotExpanded={
                                                  <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                  />
                                                }
                                              >
                                                {(items as FormItem[])?.map(
                                                  (
                                                    item: FormItem,
                                                    index: number
                                                  ) => {
                                                    if (index !== 0)
                                                      return (
                                                        <div>
                                                          {item.iban ||
                                                            item.email ||
                                                            item.number}
                                                        </div>
                                                      );
                                                  }
                                                )}
                                              </ExpandMore>
                                            )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <div style={{ textAlign: 'right', marginTop: 20 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  this.setState({
                                    isEditDataActive: true,
                                  })
                                }
                              >
                                <span>Daten bearbeiten</span>
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                              </Button>
                            </div>
                          </div>
                          <div hidden={!this.state.isEditDataActive}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: 8,
                                    display: 'inline-block',
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      isEditDataActive: false,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    style={{ marginRight: 4 }}
                                  />
                                  <span>Zurück zur Übersicht</span>
                                </span>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                {this.formItemKeys.map((key: any) => (
                                  <EditDataAccordion
                                    title={IdSchutzManager.getLabel(key)}
                                    dataKey={key}
                                    data={(this.state.formData as any)[key]}
                                    onUpdate={async () => {
                                      const data = await IdSchutzManager.getFormData(
                                        key
                                      );

                                      const newData: any = this.state.formData;
                                      newData[key] = data;

                                      this.setState(newData);

                                      await IdSchutzManager.setUserUpdated({
                                        updated: true,
                                      });
                                      // await UserManager.setUserUpdated();
                                    }}
                                  ></EditDataAccordion>
                                ))}

                                <EditUserDataAccordion
                                  title="Führerscheinnummer"
                                  dataKey="driversLicenseNumber"
                                  data={
                                    this.state.user?.driversLicenseNumber || ''
                                  }
                                  onUpdate={async () => {
                                    const user = await IdSchutzManager.getUser();

                                    this.setState({ user });

                                    await IdSchutzManager.setUserUpdated({
                                      updated: true,
                                    });

                                    // await UserManager.setUserUpdated();
                                  }}
                                />
                                <EditUserDataAccordion
                                  title="Personalausweisnummer"
                                  dataKey="nationalIdNumber"
                                  data={this.state.user?.nationalIdNumber || ''}
                                  onUpdate={async () => {
                                    const user = await IdSchutzManager.getUser();

                                    this.setState({ user });

                                    await IdSchutzManager.setUserUpdated({
                                      updated: true,
                                    });

                                    // await UserManager.setUserUpdated();
                                  }}
                                />
                                <EditUserDataAccordion
                                  title="Reisepassnummer"
                                  dataKey="passportNumber"
                                  data={this.state.user?.passportNumber || ''}
                                  onUpdate={async () => {
                                    const user = await IdSchutzManager.getUser();

                                    this.setState({ user });

                                    await IdSchutzManager.setUserUpdated({
                                      updated: true,
                                    });

                                    // await UserManager.setUserUpdated();
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div
                                  className="contentBox"
                                  style={{ height: 'auto' }}
                                >
                                  <h3>Haftungsausschluss</h3>
                                  <p>
                                    Die gefundenen Daten in der Auskunft
                                    k&ouml;nnen aus kriminellen Aktivit&auml;ten
                                    stammen. Aufgrund der Art dieser Daten und
                                    Austauschplattformen und der Tatsache, dass
                                    diese von Kriminellen stammen k&ouml;nnen,
                                    kann der Identit&auml;tsschutz die
                                    Richtigkeit der gemachten Ausk&uuml;nfte
                                    &uuml;ber Ihre Funde nicht garantieren.
                                    Bitte lesen Sie f&uuml;r weitere
                                    Informationen die Allgemeinen
                                    Gesch&auml;ftsbedingungen.
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={this.state.tabActiveIndex !== 2}
                      id="simple-tabpanel-2"
                      aria-labelledby="simple-tab-2"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="contentBox">
                            <h3>Tipps & Tricks</h3>
                            <Accordion
                              items={[
                                {
                                  title: `Was ist ein Identitätsdiebstahl und warum muss ich
                                  mich davor schützen?`,
                                  content: `
                                    <p>
                                      Ihre Identit&auml;t ist Ihr h&ouml;chstes Gut, welches jederzeit gesch&uuml;tzt werden sollte. 
                                      Opfer eines Betrugs oder Missbrauchs zu werden kann Ihnen sowohl finanziell als auch psychisch schaden und Ihnen eine Menge Zeit kosten.
                                    </p>
                                    <p>
                                      Ein Missbrauch Ihrer Daten f&auml;llt unter die Form der Kriminalit&auml;t, die in vielen verschieden Arten auftreten kann. 
                                      Stielt ein Hacker Ihre Kreditkartennummer oder auch nur Ihre Anmeldedaten, kann er Sie entweder an unbefugte Dritte verkaufen 
                                      oder sie selbst nutzen, um sich daran zu bereichern. Dies nennt man dann einen Identit&auml;tsdiebstahl, welchen es vorzubeugen gilt. 
                                      Ihr Wissen kann Sie sch&uuml;tzen. Nicht-Wissen ist der Ausl&ouml;ser f&uuml;r viele Hackerangriffe, da die Hacker sich meistens eher 
                                      die "leichte Beute" zum Opfer nehmen.
                                    </p>
                                    <p>
                                      Daher ist es so wichtig seine Daten proaktiv zu sch&uuml;tzen und Ma&szlig;nahmen zu ergreifen. 
                                      Mit dem Identit&auml;tsschutz und dem Wissen &uuml;ber Hackerangriffe erschweren Sie dem Dieb das Stehlen Ihrer Daten 
                                      und k&ouml;nnen zudem erste Anzeichen eines Betrugs schneller entdecken und sofort reagieren, um gr&ouml;&szlig;eren Schaden zu vermeiden.
                                    </p>`,
                                },
                                {
                                  title: `Identitätsdiebstahl – Wie hilft mir der Identitätsschutz einen Diebstahl vorzubeugen?`,
                                  content: `<p>Meist bemerkt man einen Identit&auml;tsdiebstahl erst dann, wenn es zu sp&auml;t ist. Jetzt kommt es vor allem darauf an welche Daten und Informationen betroffen sind und wie man dagegen angeht und sich vor kriminellen Aktivit&auml;ten unter der eigenen Identit&auml;t sch&uuml;tzt.</p>
                                  <p>Mit dem Identit&auml;tsschutz ist man nicht nur sicherer vor Angriffen, sondern er ist essenziell f&uuml;r die fr&uuml;hzeitige Erkennung und Vorbeugung und liefert ein Werkzeug, um gegen einen solchen Eingriff vor zu gehen. Egal ob Pass, Geldb&ouml;rse oder die Kreditkarte, der Schutz der eigenen Finanzen, der Kreditw&uuml;rdigkeit und der Identit&auml;t nimmt an Wichtigkeit zu, denn die Internetkriminalit&auml;t steigt rasant an.</p>
                                  <p>Dabei dienen das Internet und das Darknet als Grundlage, um Identit&auml;ten zu erzeugen. Das irische Unternehmen Experian stellt 2019 folgende Daten zur Verf&uuml;gung:</p>
                                  <ul>
                                  <li>80% der Internetkriminalit&auml;t startet mit einem Identit&auml;tsdiebstahl</li>
                                  <li>T&auml;glich werden 1 Million Menschen Opfer von Identit&auml;sdiebstahl im Internet</li>
                                  <li>73% der Menschen sind besorgt &uuml;ber den Schutz Ihrer pers&ouml;nlichen Daten im Internet</li>
                                  <li>63% wurden Opfer eines Zahlungskarten oder Online Banking Betrugs</li>
                                  <li>Hacker greifen alle 39 Sekunden an, durchschnittlich 2.244 Mal am Tag (Universit&auml;t von Maryland)</li>
                                  <li>94% der Malware wurde per E-Mail geliefert. (Verizon)</li>
                                  </ul>
                                  <p>Das Data Monitoring hilft dabei einen Diebstahl der Identit&auml;t fr&uuml;hzeitig zu erkennen und dem vorzubeugen und ist somit ein Bestandteil des ID Schutzes.</p>
                                  <p>Beim Data Monitoring wird nach den von Ihnen eingegebenen oder gestohlen Daten im Netz gesucht. Die Ergebnisse dieser Suche werden Ihnen in dem ID Schutz-Portal angezeigt. Dort sehen Sie sowohl Warnmeldungen als auch die Suchergebnisse im Internet. Gesucht wird nach allen Daten, die Sie vorher eingegeben haben. Dies k&ouml;nnen Nummern Ihrer Kreditkarten, Bankkonten oder Ihrer P&auml;sse sein. Auch nach E-Mail-Adresse oder Telefonnummern oder gar Anschriften l&auml;sst sich suchen.</p>`,
                                },
                                {
                                  title: `Wie kann ich meinen Computer vor Viren oder anderen Programmen schützen?`,
                                  content: `
                                  <ul>
                                  <li>Achten Sie immer darauf, dass Sie eine sichere Datenverschl&uuml;sselung und Internetverbindung nutzen.</li>
                                  <li>Investieren Sie in eine (oder auch mehrere) Antiviren- und Antimalware- Software und installieren Sie diese, um Ihren Computer zu sch&uuml;tzen.</li>
                                  <li>Aktualisieren Sie regelm&auml;&szlig;ig Ihre Apps, Programme, Browser und Betriebssysteme.</li>
                                  <li>Wenn Ihnen Sicherheits-Patches oder Software-Updates von vertrauensw&uuml;rdigen Quellen angeboten werden, installieren Sie auch diese, um Ihren Computer zu sch&uuml;tzen.</li>
                                  <li>Wenn Sie Ihren Computer nicht mehr nutzen, schalten Sie ihn aus oder trennen die Internetverbindung.</li>
                                  <li>Achten Sie auf Phishing Mails &ndash; wer auch immer Ihnen eine E-Mail schickt, Dateien im Anhang k&ouml;nnen beim Download oder auch nur beim &Ouml;ffnen, Viren oder andere Softwares enthalten, die Ihren Computer in der Sicherheit beeintr&auml;chtigen k&ouml;nnen. Auch Audiodateien, Bilder, Links und Videos k&ouml;nnen versteckte Viren enthalten.</li>
                                  <li>Vorsicht bei E-Mails, bei denen Sie mit einer allgemeinen Anrede angeschrieben werden z.B. &bdquo;Lieber Kunde&hellip;&ldquo;. Die meistens Firmen oder auch Banken nutzen in der Anrede meistens Ihren Namen. Bevor Sie auf solch eine E-Mail reagieren, sollten Sie sich diese erst vollst&auml;ndig durchlesen.</li>
                                  <li>Meiden Sie potenziell gef&auml;hrliche Internetseiten, wie z.B. Seiten mit anst&ouml;&szlig;igem Inhalt. Diese k&ouml;nnen Viren enthalten. Im Betriebssystem eines Mobiltelefons sind diese Viren nur sehr schwer aufzufinden.</li>
                                  <li>Achten Sie vor den herunterladen von Apps auf negative Bewertungen von anderen Nutzern. Erlauben Sie einer App keine Zugriffe auf den Kalender, der Kamera usw., wenn Sie nicht wissen, wieso dieser Zugriff gebraucht wird.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Wie schütze ich meine Kredit- und Bankkarten vor einem Identitätsdiebstahl?`,
                                  content: `<p>Durch den stetigen Anstieg von unserem elektronischen Handel (eCommerce) steigt auch die Gefahr des Identit&auml;tsdiebstahls und Betrugs bei elektronischen Endger&auml;ten. Somit ist es besonders wichtig, seine Kredit- und Bankkarten weitestgehend zu sch&uuml;tzen.</p>
                                  <ul>
                                  <li>Erkundigen Sie sich bei Ihrer Bank, welche Sicherheitsvorkehrungen dort angeboten werden.</li>
                                  <li>Websites, die nach Ihren Kontodaten oder Kreditkartendaten verlangen, k&ouml;nnen gehackt sein. Somit geben Sie niemals Ihre Bankdaten auf einer Website ein, wenn es keine sichere Transaktion darstellt.</li>
                                  <li>Nutzen Sie die 3-D Secure Technology mit Verified by Visa und MasterCard Secure Code. Den Sicherheitscode k&ouml;nnen Sie nach der Registrierung bei allen Online-Transaktionen sicher verwenden.</li>
                                  <li>Wenn Sie eine Bank- oder Kreditkarte erwarten und diese nicht zum vorausgesagten Termin bei Ihnen ankommt, sollten Sie sich schnellst m&ouml;glich mit Ihrer Bank in Verbindung setzten.</li>
                                  <li>Wenn Sie nicht genutzte Kreditkarten-Konten besitzen, sollten Sie diese auf jeden Fall l&ouml;schen, denn auch diese k&ouml;nnen von unbefugten Dritten f&uuml;r einen Betrug missbraucht werden.</li>
                                  <li>Bezahlen Sie nur mit Ihrer Kreditkarte, wenn Sie sicher sind, dass der Schutz Ihrer personenbezogenen Daten ausreichend ist.</li>
                                  <li>Im Falle eines Kartenverlustes, muss es schnell gehen. Daher sollten Sie all Ihre Kreditkarten und Zugangsdaten an einem sicheren Ort in Kopie aufbewahren, um diese schnellstm&ouml;glich sperren lassen zu k&ouml;nnen.</li>
                                  <li>Seien Sie stehts informiert &uuml;ber die neusten Entwicklungen hinsichtlich des Identit&auml;tsdiebstahls oder eines Betrugs.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Wie kann ich meine persönlichen Daten schützen?`,
                                  content: `<ul>
                                  <li>Achten Sie darauf, dass Sie haupts&auml;chlich mit bekannten Firmen oder Institutionen zusammenarbeiten, wenn es um personenbezogene, wichtige Unterlagen geht.</li>
                                  <li>Wenn Sie verreisen, sollten Sie so wenig wichtige Unterlagen wie nur m&ouml;glich mitnehmen. Nehmen Sie nur die Dokumente mit, die unbedingt erforderlich sind.</li>
                                  <li>Kaufen Sie sich einen Aktenvernichter (Schredder). Alte Versicherungsunterlagen, Rechnungen, Kontoausz&uuml;ge usw. sollten immer geschreddert werden, damit man die personenbezogenen Daten nicht mehr erkennen kann. Im besten Fall zerkleinert der Schredder das Papier in die kleinstm&ouml;glichen Teile. Streifen k&ouml;nnte man oftmals nochmal zusammen basteln.</li>
                                  <li>Ihre pers&ouml;nlichen Unterlagen und Daten sollten immer an einem sicheren Ort liegen. Vielleicht investiert man in einen Dokumenten-Safe.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Wie schütze ich meine Post?`,
                                  content: `<p>Angebote, die Sie per Post oder auch telefonisch erhalten, bei denen Ihnen Preise oder Gelder versprochen werden, sollten Sie immer ausschlagen. Vor allem wenn Sie gebeten werden, Ihre pers&ouml;nlichen Daten, die Bankverbindung oder die E-Mail-Adresse zu hinterlegen.</p>`,
                                },
                                {
                                  title: `Wie kann ich meine Passwörter schützen?`,
                                  content: `<p>Je komplexer ein Passwort ist, desto schwieriger ist es f&uuml;r einen unbefugten Dritten es zu hacken. Daher ist es sehr wichtig zu wissen, wie man ein Passwort sicher erstellt.</p>
                                  <ul>
                                  <li>Ein Passwort sollte niemals aus pers&ouml;nlichen Daten wie eines Geburtsdatums oder &auml;hnlichem bestehen. Auch Teile der Telefonnummer oder der Passnummer k&ouml;nnen schon leichter gehackt werden als ein v&ouml;llig erfundenes Passwort.</li>
                                  <li>Zahlenabfolgen wie &bdquo;1234&ldquo; oder auch &bdquo;0000&ldquo; sollten gemieden werden, denn auch die sind nicht sehr komplex.</li>
                                  <li>Falls Sie sich Ihre Passw&ouml;rter notieren m&uuml;ssen, sollten Sie diese nicht mit sich f&uuml;hren und sicher in einem Safe oder an einem sicheren Ort bei Ihren anderen Unterlagen verstauen.</li>
                                  <li>Ein sicheres Passwort sollte viele verschiedene Komponenten enthalten:
                                  <ul>
                                  <li>Gro&szlig;buchstaben, Kleinbuchstaben, Zahlen und wenn erlaubt auch Sonderzeichen.</li>
                                  <li>Das Passwort sollte f&uuml;r Sie eine Bedeutung haben, welche aber so keiner kennt. Somit ist es sehr komplex und f&uuml;r Sie sich leicht zu merken.</li>
                                  <li>Ein Beispiel k&ouml;nnte sein: Ka743tze#.1997!</li>
                                  </ul>
                                  </li>
                                  </ul>`,
                                },
                                {
                                  title: `Wie kann ich meine persönlichen Daten in den
                                  Sozialen Netzwerken sichern?`,
                                  content: `<p>Um mit den Freunden und der Familie in Verbindung zu bleiben nutzen viele die sozialen Netzwerke. Doch auch hier muss ein sicherer Umgang gegeben sein, um Sie vor dem Missbrauch Ihrer Daten zu sch&uuml;tzen.</p>
                                  <ul>
                                  <li>Grundlegend sollte man immer darauf achten, dass man mit einer sicheren Verbindung in den Sozialen Netzwerken unterwegs ist. Bei z.B. Facebook kann man ganz einfach nachschauen, ob die Verbindung sicher ist. Man erkennt es daran, dass in der unteren rechten Ecke des Browsers ein kleines Schlosssymbol angezeigt wird. Zudem kann man die Internetadresse pr&uuml;fen. Eine https:// Verbindung ist eine sichere Verbindung. Wenn dort nur http:// stehen sollte, sollte man die Einstellungen auf der Seite des Sozialen Netzwerks &auml;ndern.</li>
                                  <li>Weniger ist mehr! Geben Sie nicht zu viele Informationen &uuml;ber Ihre Person in Ihrem Profil an. Je mehr ein Hacker &uuml;ber Sie wei&szlig;, desto einfacher ist es f&uuml;r ihn, Sie zu sch&auml;digen. Nat&uuml;rlich kann man einzelne Angaben machen, jedoch sollte man damit stets vorsichtig und eher zur&uuml;ckhaltend sein.</li>
                                  <li>Passen Sie Ihre Datenschutz-Standardeinstellungen in den Sozialen Netzwerken an. Diese Standardeinstellungen generieren einen hohen Internetverkehr und einen m&ouml;glichst gro&szlig;en Datenzuwachs. Dadurch k&ouml;nnen Ihre Daten auch au&szlig;erhalb des Sozialen Netzwerks sichtbar werden. Somit k&ouml;nnen Sie Ihre Daten sch&uuml;tzen, indem Sie die Einstellungen nach Ihren W&uuml;nschen anpassen.</li>
                                  <li>Das Internet vergisst nie! Seien Sie somit sehr bedacht, was Sie posten. Ein sogenannter Post verschwindet nicht einfach wieder und auch wenn Sie ihn l&ouml;schen, kann es sein, dass er auf den Computern anderer Personen immer noch existiert. Bevor Sie etwas posten, sollten Sie sich somit immer die Frage stellen, ob Sie diese Informationen f&uuml;r immer &ouml;ffentlich machen wollen oder anderen zug&auml;nglich machen wollen.</li>
                                  <li>Der Identit&auml;tsdiebstal in den Sozialen Netzwerken nimmt heutzutage immer mehr zu. Daher seien Sie vorsichtig bei den sogenannten Freundschaftsanfragen. Nehmen Sie nur die &bdquo;Freunde&ldquo; an, die Sie auch wirklich kennen und vergewissern Sie sich vorher, ob es wirklich auch die Person ist, f&uuml;r die sich ausgegeben wird. Anhand der Angaben der Person k&ouml;nnen Sie schnell und einfach pr&uuml;fen, ob es sich wirklich um Ihren Freund oder Ihre Freundin handelt. Personen, die keine Informationen hinterlegt haben, sind mit Vorsicht zu betrachten.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Wie kann ich mich auf Geschäfts- oder auch
                                  Privatreisen vor einem Identitätsdiebstahl
                                  schützen?`,
                                  content: `<ul>
                                  <li>Bevor Sie eine Reise antreten k&ouml;nnen Sie all Ihre pers&ouml;nlichen Daten in Form einer Datensicherung auf einem Ger&auml;t speichern, welches Sie nicht mitnehmen, und diese Daten auf Ihrem Mobilen Ger&auml;t l&ouml;schen. So kann verhindert werden, dass bei einem Diebstahl, der Dieb all Ihre Daten zur freien Verf&uuml;gung hat.</li>
                                  <li>Die sichere Internetverbindung spielt auf Reisen eine enorm wichtige Rolle. Meiden Sie die &ouml;ffentlichen WLAN-Verbindungen und Hotspots. Die kabelgest&uuml;tzte Verbindung Ihres Hotels ist oftmals viel sicherer als eine &ouml;ffentliche WLAN-Verbindung.</li>
                                  <li>&Auml;hnlich wie mit den &ouml;ffentlichen WLAN-Verbindungen ist es mit den &ouml;ffentlichen Computern in Bibliotheken, Jugendherbergen oder auch in Hotels. Diese Computer k&ouml;nnen eine Malware installiert haben, die die Tastatureingaben protokolliert und ein unbefugter Dritter so &bdquo;mitlesen&ldquo; kann, was Sie eingeben. Diese Computer sollten Sie somit nicht nutzen, um Ihren Bankstatus zu checken oder auch nicht, um sich in den sozialen Netzwerken einzuloggen.</li>
                                  </ul>
                                  <p>Falls es trotz aller Sicherheitsma&szlig;nahmen zu einem Missbrauch Ihrer Daten kommen sollte, gilt auch hier, sofort reagieren, um den Schaden so gering wie m&ouml;glich zu halten.</p>`,
                                },
                                {
                                  title: `Wie schütze ich meine Identität am Arbeitsplatz
                                  und muss ich das Überhaupt?`,
                                  content: `<p>Jeder sollte seine Identit&auml;t privat und auch am Arbeitsplatz sch&uuml;tzen. Man kann nicht davon ausgehen, dass Ihr Arbeitsplatz von Hackerangriffen verschont bleibt.</p>
                                  <ul>
                                  <li>Zu Ihren pers&ouml;nlichen Daten geh&ouml;ren nicht nur Ihre E-Mail-Adresse und Telefonnummer, sondern nat&uuml;rlich auch das Portemonnaie und Ihre pers&ouml;nlichen Unterlagen. Verstauen Sie Ihre Wertgegenst&auml;nde und sensible Dokumente an einem sicheren Ort, der auch wenn Sie zum Mittagessen aufbrechen.</li>
                                  <li>Wissen ist ein wichtiges Gut, wenn es um Sicherheitsma&szlig;nahmen geht. Daher sollten Sie sich immer &uuml;ber die Sicherheitsma&szlig;nahmen informieren. Dies gilt nicht nur f&uuml;r den Arbeitsplatz, sondern bei allen anderen Institutionen, wo Ihre pers&ouml;nlichen Daten erfasst werden, wie z.B. beim Arzt.</li>
                                  <li>Sie haben das Recht zu erfahren, wer alles Zugang zu Ihren pers&ouml;nlichen Daten hat und von diesem Recht sollten Sie auch Gebrauch machen, um sicher zu stellen, dass Ihre Daten einen umf&auml;nglichen Schutz genie&szlig;en.</li>
                                  <li>Ein Software-Update dient oftmals auch einer Behebung vorhandener Sicherheitsl&uuml;cken. F&uuml;hren Sie die Software-Updates durch, sobald Sie zur Verf&uuml;gung stehen und aktualisieren Sie somit auch Ihre Sicherheitsma&szlig;nahmen.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Welche Risiken bringt das öffentliche Wlan mit
                                  sich?`,
                                  content: `<p>Vom Lesen der Browsing Aktivit&auml;ten bis hin zum Stehlen der Kreditkartennummer oder des Passworts, kann alles vorkommen, wenn man sich mit einem &ouml;ffentlichen Wlan verbindet. Die H&auml;cker haben verschiedene M&ouml;glichkeiten, um an die Daten der Personen zu kommen, die sich mit einem &ouml;ffentlichen Wlan verbinden. Eine sehr verbreitete Methode ist beispielsweise die "Man in the middle" Methode, mit der sich der Hacker zwischen Wlan und User einschleust und so alle Daten des Users abf&auml;ngt. Selbst den Wohnort des Users kann der Hacker zur&uuml;ckverfolgen.</p>`,
                                },
                                {
                                  title: `Wie kann ich mich im öffentlichen Wlan vor
                                  Cyberkriminalität schützen?`,
                                  content: `<p>Sch&uuml;tzen k&ouml;nnen Sie sich schon mit kleinen Aufmerksamkeiten. Sch&uuml;tzen Sie Ihre Daten:</p>
                                  <ul>
                                  <li>Indem Sie f&uuml;r jeden Account ein anderes Passwort nutzen, &ouml;ffnen Sie keine Anh&auml;nge aus unbekannten E-Mail Absendern</li>
                                  <li>Vermeiden Sie im &ouml;ffentlichen Wlan Bankgesch&auml;fte zu erledigen.</li>
                                  <li>Verwenden Sie, wenn m&ouml;glich immer die &bdquo;https&ldquo; Option</li>
                                  <li>Deaktivieren Sie Ihr Wlan wenn Sie es nicht benutzen.</li>
                                  <li>Aktivieren Sie die Firewall Ihres Ger&auml;tes oder verwenden Sie eine VPN</li>
                                  <li>Installieren Sie ein Anti-Viren-Software auf Ihrem Ger&auml;t</li>
                                  </ul>
                                  <p>Mit diesen Sicherheitsma&szlig;nahmen kommen Sie Ihren M&ouml;glichkeiten sich zu sch&uuml;tzen nach, Hackerangriffe lassen sich jedoch nicht zuverl&auml;ssig dadurch ausschlie&szlig;en.</p>`,
                                },
                                {
                                  title: `Wie schütze ich meine Kontoauszüge und meine
                                  Bankdaten online und auch offline?`,
                                  content: `<ul>
                                  <li>&Uuml;berwachen Sie Ihre Kontobewegungen regelm&auml;&szlig;ig. So k&ouml;nnen Sie fr&uuml;hzeitig auf verd&auml;chtige Aktivit&auml;ten aufmerksam werden und reagieren. Sie k&ouml;nnen sich im Online-Banking durch eine einstellbare Warnmeldung &uuml;ber Ihre Kontobewegungen informieren lassen.</li>
                                  <li>Falls Sie kein Online-Banking nutzen und Ihre Kontoausz&uuml;ge mehrere Tage lang nicht eintreffen, wenden Sie sich bitte telefonisch an Ihre Bank oder Ihr Kreditkartenunternehmen und lasse Sie sich &uuml;ber Ihre Kontobewegungen und Ihren Kontostand informieren.</li>
                                  <li>Allgemein gilt jedoch: Elektronische Kontoausz&uuml;ge sind schwieriger zu stehlen und daher sicherer. Versuchen Sie demnach sich Ihre Kontoausz&uuml;ge online zur Verf&uuml;gung stellen zu lassen.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Die wichtigsten Ziele in Bezug auf den
                                  Identitätsdiebstahl`,
                                  content: `<p>Wenn Sie Opfer eines Identit&auml;tsdiebstahls geworden sind, sind Ihre wichtigsten Ziele und Schritte folgende:</p>
                                  <ul>
                                  <li>L&ouml;schen Sie umgehend alle Online-Accounts, die von Betr&uuml;gern missbraucht oder manipuliert worden sind.</li>
                                  <li>Stellen Sie sicher, dass die Kosten, die der Betr&uuml;ger im Rahmen seiner kriminellen Aktivit&auml;t in Ihrem Namen begangen hat, Ihnen nicht zur Last gelegt werden und Sie nicht haftbar gemacht werden.</li>
                                  <li>Melden Sie diesen Vorfall unverz&uuml;glich der Polizei und erstatten Sie Anzeige.</li>
                                  </ul>
                                  <p>Um diese drei Schritte schnellstm&ouml;glich zu erledigen, kann es helfen sich einen Handlungsplan zu erstellen. Dieser kann wie eine Art Check-Liste aufgebaut sein und sollte auch alle Infos zu den Dokumenten enthalten, welche Sie brauchen und wo Sie diese erhalten k&ouml;nnen.</p>`,
                                },
                                {
                                  title: `Was muss ich beachten, sollte ich mir
                                  Informationen zu meinem Betrugsfall einholen
                                  wollen?`,
                                  content: `<p>wichtige Schritte:</p>
                                  <ul>
                                  <li>Notieren Sie sich Ihre Fragen und die Informationen, die Sie erhalten m&ouml;chten vor dem Gespr&auml;ch mit beispielsweise dem betroffenen Unternehmen oder der Bank</li>
                                  <li>Lassen Sie sich nicht abwimmeln und entwickeln Sie eine Strategie, um auf m&ouml;gliche Abwehrreaktionen eingehen zu k&ouml;nnen. Fragen Sie beispielsweise nach wer einem denn dann weiterhelfen kann, wenn der Ansprechpartner vorgibt nicht zust&auml;ndig zu sein.</li>
                                  <li>H&ouml;ren Sie gut zu und machen sich Notizen von den von Ihnen ben&ouml;tigten Informationen und den Ansprechpartnern.</li>
                                  <li>Verlangen Sie gegebenenfalls einen Vorgesetzten zu sprechen, falls man Sie hinhalten m&ouml;chte und stellen Sie sicher, dass Sie das Gespr&auml;ch erst beenden, wenn Sie alle Fragen/Infos Ihres vorbereiteten Notizzettels erhalten haben</li>
                                  </ul>
                                  <p>Was ist sonst noch f&uuml;r die Organisation meines Falls zu beachten?</p>
                                  <ul>
                                  <li>Senden Sie geforderte Dokumente immer per Einschreiben, Eil oder Wertbrief an den Zust&auml;ndigen und machen sie sich vorher eine Sicherheitskopie</li>
                                  <li>Notieren Sie sich nicht nur Namen aller Ansprechpartner, sondern auch Datum und Uhrzeit des Gespr&auml;chs</li>
                                  <li>Bei wichtigen Originaldokumenten wie z. B. Polizeiberichten versenden Sie immer nur eine Kopie und behalten Sie immer das Original</li>
                                  <li>Bewahren Sie alle Informationen, Dokumente und sonstige Unterlagen zu Ihrem Fall auch nach Kl&auml;rung weiterhin auf, weil Sie ein wichtiges Beweismittel f&uuml;r eventuell sp&auml;tere Fragen sein k&ouml;nnen.</li>
                                  </ul>`,
                                },
                                {
                                  title: `Was mache ich, wenn mir mein Personalausweis
                                  gestohlen worden ist?`,
                                  content: `<p>Trotz h&ouml;chster Anforderung an die Dokumentensicherheit wird der Personalausweis immer wieder f&uuml;r kriminelle Handlungen missbraucht. Es ist also wichtig, einen Verlust oder Diebstahl unverz&uuml;glich der Polizei zu melden, um nicht f&uuml;r strafbare Handlungen belangt werden zu k&ouml;nnen, die man selbst nicht begangen hat. Zudem sollten Sie, wenn Sie Ihren Personalausweis verloren haben, umgehend die folgende Hotline zum Sperren des Personalausweises anrufen: <strong>116 116</strong> oder alternativ aus dem Ausland <strong>0049-30-40 50 40 50</strong> (geb&uuml;hrenpflichtig). Dieser Service ist rund um die Uhr verf&uuml;gbar (24/7).</p>
                                  <p><strong>Bitte beachten:</strong> Um die eID Funktion, wenn Sie Ihren Personalausweis wiederfinden, entsperren zu lassen, m&uuml;ssen sie Ihre Personalausweisbeh&ouml;rde aufsuchen. Es ist in diesem Fall nicht m&ouml;glich die Funktion per Telefon zu entsperren. Auch die Signatur Funktion kann weder von der oben genannten Hotline noch von der Meldebeh&ouml;rde gesperrt werden.</p>
                                  <p>N&auml;here Informationen dazu finden sie unter:<br /><a href="www.personalausweisportal.de/EN/Citizens/German_ID_Card/In-case-of-loss/Loss_node.html" target="_blank">www.personalausweisportal.de/EN/Citizens/German_ID_Card/In-case-of-loss/Loss_node.html</a></a></p>`,
                                },
                                {
                                  title: `Wie erhalte ich einen neuen Personalausweis?`,
                                  content: `<p>Einen neuen Personalausweis erhalte ich &uuml;ber einen Antrag bei der &ouml;rtlichen Meldebeh&ouml;rde.<br />F&uuml;r Personen bis 24 Jahre kostet dies 22,80 EUR und hat eine G&uuml;ltigkeit von 6 Jahren. Nach dem 24. Lebensjahr kostet er 28,80 EUR und ist 10 Jahre g&uuml;ltig. Da die allgemeine Bearbeitungszeit von 6 Wochen, bis der Personalausweis verf&uuml;gbar ist, f&uuml;r viele zu lang ist, besteht die M&ouml;glichkeit eines vorl&auml;ufigen Ausweises. Dieser wird sofort ausgestellt und kostet 10 Euro. Um einen vorl&auml;ufigen Ausweis beantragen zu k&ouml;nnen ben&ouml;tigen Sie folgende Unterlagen:</p>
                                  <ul>
                                  <li>Geburtsurkunde, Heiratsurkunde (Familienbuch)</li>
                                  <li>Biometrisches Passfoto</li>
                                  <li>Polizeibericht bei Diebstahl</li>
                                  <li>Bei Verlust m&uuml;ssen Sie eine Verlusterkl&auml;rung abgeben</li>
                                  </ul>`,
                                },
                                {
                                  title: `Was mache ich, wenn meine Kredit- oder Bankkarte
                                  gestohlen wurde oder ich sie verloren habe?`,
                                  content: `<p>Unabh&auml;ngig davon ob die Karte geklaut oder gestohlen worden ist, kann durch den Verlust ein hoher finanzieller Schaden entstehen. Vor allem dann, wenn der Kreditrahmen noch sehr hoch ist.</p>
                                  <p>Daher ist es besonders wichtig schnell zu handeln und umgehend den Verlust der Karte zu melden und diese auch telefonisch unter der Standard-Telefonnummer 116 116 sperren zu lassen. Unter dieser Nummer kann man rund um die Uhr alle Bank- und Kreditkarten sperren lassen. Anrufe innerhalb von Deutschland sind geb&uuml;hrenfrei.</p>
                                  <p>Sobald die Karte gesperrt worden ist, sollten Sie Ihre Kontobewegungen vor der Sperrung auf betr&uuml;gerische Abbuchungen pr&uuml;fen.</p>
                                  <p>Neue Bank- und Kreditkarten k&ouml;nnen Sie bei Ihrer Bank beantragen. Sparkassen und Banken fordern in der Regel eine Geb&uuml;hr von ca. 10-20 Euro pro Karte.</p>`,
                                },
                                {
                                  title: `Was mache ich, wenn mein Führerschein gestohlen
                                  wurde oder ich ihn verloren habe?`,
                                  content: `<p>Bei einem Diebstahl des F&uuml;hrerscheins k&ouml;nnen die Daten zur f&auml;lschlichen Identifizierung des Diebes genutzt werden.</p>
                                  <p>Daher ist es besonders wichtig einem solchen Verlust eine Verlusterkl&auml;rung bei Ihrer F&uuml;hrerscheinstelle abzugeben. Ist Ihnen der F&uuml;hrerschein gestohlen worden, sollten Sie dies umgehend bei der Polizei anzeigen.</p>
                                  <p>Einen neuen F&uuml;hrerschein k&ouml;nnen Sie dann bei der &ouml;rtlichen F&uuml;hrerscheinstelle beantragen. Dazu ben&ouml;tigen Sie Ihren Personalausweis oder Pass und ein biometrisches Passfoto. Wenn Ihr F&uuml;hrerschein an einem anderen Ort ausgestellt worden ist, m&uuml;ssen Sie eine Kopie File Card Transcript per Telefon, Brief oder per Mail bei der F&uuml;hrerscheinstelle beantragen, die Ihren F&uuml;hrerschein erstmalig ausgestellt hat. Dann werden die Daten der Flashcard direkt an die aktuell zust&auml;ndige Stelle geschickt und sie k&ouml;nnen Ihren F&uuml;hrerschein dort beantragen.</p>
                                  <p>Der F&uuml;hrerschein kostet 35 Euro. F&uuml;r die Verlusterkl&auml;rung k&ouml;nnen weitere Kosten in H&ouml;he von ca. 30 Euro anfallen. Wenn Sie sich einen vorl&auml;ufigen F&uuml;hrerschein ausstellen lassen, werden Ihnen 12.50 Euro daf&uuml;r berechnet.</p>`,
                                },
                                {
                                  title: `Was mache ich, wenn mein Pass gestohlen wurde oder
                                  ich ihn verloren habe?`,
                                  content: `<p>Bei einem Pass-Diebstahl ist die Gefahr gro&szlig;, dass Ihre Identit&auml;t daf&uuml;r missbraucht wird, illegal in das Land einzureisen. Zudem k&ouml;nnen Sie sich selbst nicht mehr ausweisen, was in Deutschland Pflicht ist.</p>
                                  <p>Den Diebstahl sollten Sie sofort bei der Polizei melden und sich dann umgehend um einen neuen Pass bem&uuml;hen. Den k&ouml;nnen Sie bei der &ouml;rtlichen Meldestelle neu beantragen. Daf&uuml;r ben&ouml;tigen Sie folgende Unterlagen:</p>
                                  <ul>
                                  <li>Geburtsurkunde, Heiratsurkunde</li>
                                  <li>Biometrisches Passfoto</li>
                                  <li>Polizeibericht bei Diebstahl</li>
                                  <li>Bei Verlust muss eine Verlusterkl&auml;rung abgegeben werden.</li>
                                  <li>Falls vorhanden muss der Personalausweis oder der F&uuml;hrerschein vorgelegt werden</li>
                                  </ul>
                                  <p>Bei einem Verlust des Passes m&uuml;ssen Sie vorher eine Verlusterkl&auml;rung bei der &ouml;rtlichen Beh&ouml;rde abgeben. Den Pass erhalten Sie in der Regel 2-6 Wochen nach Beantragung, es sei denn, Sie beantragen ihn per Express. Dann erhalten Sie den Pass schon nach 2 Werktagen.</p>
                                  <p><span style="text-decoration: underline;">Die Kosten f&uuml;r den Prozess sehen wie folgt aus:</span></p>
                                  <ul>
                                  <li>Pass mit 32 Seiten f&uuml;r Antragsteller &uuml;ber 24 Jahre: 59 Euro</li>
                                  <li>Pass mit 48 Seiten f&uuml;r Antragsteller &uuml;ber 24 Jahre: 81 Euro</li>
                                  <li>Pass mit 32 Seiten f&uuml;r Antragsteller unter 24 Jahre: 37,50 Euro</li>
                                  <li>Pass mit 32 Seiten f&uuml;r Antragsteller unter 24 Jahre: 59,50 Euro</li>
                                  <li>Vorl&auml;ufiger Pass: 26 Euro</li>
                                  <li>36 Euro zus&auml;tzlich, wenn Sie den Pass per Express beantragen.</li>
                                  </ul>
                                  <p><strong>Zu beachten ist noch:</strong><br />Wenn Sie Ihren Pass direkt vor Abreise in ein anderes Land nicht finden k&ouml;nnen, m&uuml;ssen Sie sich am Flughafen vor Ort an die Bundespolizei wenden. Dort erhalten Sie dann einen Notausweis, welcher 8 Euro kostet und f&uuml;r die Reise erstmal ausreicht.<br />Wird Ihnen der Pass im Ausland gestohlen oder Sie verlieren Ihn dort, m&uuml;ssen Sie sich vor Ort an die deutsche Botschaft wenden oder an das zust&auml;ndige Konsulat. Sie erhalten dort ein Reisedokument, welches einen Pass vorerst ersetzt und Ihnen die Ausreise erm&ouml;glicht. Dieses Dokument erhalten Sie in der Regel einen Tag sp&auml;ter.</p>
                                  <p><span style="text-decoration: underline;">Die anfallenden Kosten sehen hier wie folgt aus:</span></p>
                                  <ul>
                                  <li>21 Euro Geb&uuml;hr f&uuml;r das Reisedokument + die anfallenden Kosten f&uuml;r Telefonate und Faxe</li>
                                  <li>Au&szlig;erhalb der Dienstzeiten kostet diese Geb&uuml;hr 29 Euro statt 21 Euro.</li>
                                  <li>Ein vorl&auml;ufiger Pass kostet 36 Euro</li>
                                  </ul>`,
                                },
                                {
                                  title: `Was mache ich, wenn ich meine Krankenkassenkarte
                                  gestohlen wurde oder ich sie verloren habe?`,
                                  content: `<p>Melden Sie diesen Verlust Ihrer Krankenversicherung. Diese wird Ihnen eine Bescheinigung ausstellen, die Ihnen einen Arztbesuch, bis Ihre neue Karte da ist, erm&ouml;glicht. Eine neue Versicherungskarte erhalten Sie in der Regel kostenlos von Ihrer Krankenversicherung.</p>
                                  <p>Bringen Sie zusammen mit Ihrer Krankenversicherung in Erfahrung, ob Ihre Karte in der Zwischenzeit f&uuml;r betr&uuml;gerische Zwecke missbraucht wurde.</p>`,
                                },
                                {
                                  title: `Was mache ich, wenn ich Opfer eines
                                  Online-Identitätsdiebstahls geworden bin?`,
                                  content: `<ol>
                                  <li>Was ist Online Identit&auml;tsdiebstahl?<br />Ihr Computer wird meist durch Downloads oder E-Mail-Anh&auml;nge infiziert, wodurch Hacker Ihre Identit&auml;t online mit Spyware oder Malware stehlen. <br />Diese Softwares laufen im Hintergrund und erfassten zum Beispiel ihr Verhalten im Internet oder protokollieren genaustens Ihre Tastatureingaben.<br />So werden Ihr pers&ouml;nlichen Kredit- und Bankkarten sowie Passw&ouml;rter und andere pers&ouml;nliche Daten abgefangen. Solche Hacker nutzen diese Daten f&uuml;r Online K&auml;ufe oder Abhebungen von Bankkonten. <br /><br />Sogenannte "Phishing-Mails" die einen gef&auml;lschten Inhalt und oftmals Verlinkungen auf Websites erhalten, bei denen sie Ihre pers&ouml;nlichen Daten eingeben, ist eine weitere weit verbreitete Methode f&uuml;r Identit&auml;tsdiebstahl.<br /><br /></li>
                                  <li>Was kann ich tun, wenn ich einen Identit&auml;tsdiebstahl vermute?<br />Wenn Sie eine betr&uuml;gerische Bewegung auf einem Ihrer Accounts wie beispielsweise eBay oder PayPal vermuten, sollten sie &uuml;berpr&uuml;fen &uuml;ber welche Ihrer Bankverbindungen diese Bewegung stattgefunden hat.<br />Haben sie das passende Konto, k&ouml;nnen Sie die Schritte f&uuml;r das Verfahren bei einer verloren gegangenen oder gestohlenen Bank oder Kreditkarte befolgen.</li>
                                  <li>Was muss ich bei einer unberechtigten Nutzung von Online-Accounts befolgen?
                                  <ul>
                                  <li>Zuerst sollten Sie ausschlie&szlig;en, dass kein Angeh&ouml;riger oder eine andere bekannte Person f&uuml;r die unbekannten Aktivit&auml;ten verantwortlich ist.</li>
                                  <li>Sprechen Sie den Anbieter des betroffenen Online-Accounts auf die unrechtm&auml;&szlig;ige Nutzung Ihres Kontos an.</li>
                                  <li>S&auml;mtliche Passw&ouml;rter, die mit dem Online-Accounts in Verbindung stehen (z. B. die des E-Mail Accounts, PayPal Zugang etc.) sollten unverz&uuml;glich ge&auml;ndert werden.</li>
                                  <li>Fordern Sie, wenn Sie nicht die M&ouml;glichkeit haben manuell ein neues Passwort festzulegen, bei der zust&auml;ndigen Bank ein neues Passwort f&uuml;r den Online Account an und beachten sie auch das &Auml;ndern der Sicherheitsfragen.</li>
                                  <li>&Uuml;berpr&uuml;fen Sie, ob Ihre Personendaten der betroffenen Online-Dienste noch aktuell sind oder nehmen gegebenenfalls &Auml;nderungen vor.</li>
                                  <li>Wenden Sie sich bei Fragen oder ben&ouml;tigter Hilfe jederzeit an den betroffenen Anbieter des Online-Accounts, &uuml;ber den der Identit&auml;tsdiebstahl stattgefunden hat.</li>
                                  </ul>
                                  </li>
                                  </ol>`,
                                },
                              ]}
                            ></Accordion>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={this.state.tabActiveIndex !== 3}
                      id="simple-tabpanel-3"
                      aria-labelledby="simple-tab-3"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="contentBox">
                            <h3>Fragen & Antworten</h3>
                            <Accordion
                              items={[
                                {
                                  title:
                                    'Wer erbringt den Service für meinen Identitätsschutz?',
                                  content: `<p>Den Identit&auml;tsschutz erbringt die Mehrwerk GmbH als Dienstleister der S-Markt und Mehrwert f&uuml;r Sparkassen-Kunden. Mehrwerk ist einer der f&uuml;hrenden Mehrwertanbieter im Rahmen von Sicherheits- und Serviceleistungen.</p>`,
                                },
                                {
                                  title:
                                    'Welche Quellen werden für die Überwachung des Internets verwendet?',
                                  content: `<p>Die Daten stammen von verschiedenen Websites und Internetforen, gefilterten Suchmaschinenabfragen, Twitter-Feeds, P2P-Quellen, Malware-Proben, Botnets und Torrent-Quellen und zuletzt anonymen und verborgenen Webservices.</p>`,
                                },
                                {
                                  title: 'Was ist ein sogenannter "Alarm"?',
                                  content: `<p>Der Alarm ist als Warnung daf&uuml;r zu betrachten, dass unsere &Uuml;berwachungstechnologie Daten im Internet, die Sie von uns &uuml;berwachen lassen, gefunden hat.</p>`,
                                },
                                {
                                  title:
                                    'Was ist zu tun, wenn sich der Alarm nur auf einen kleinen Teil der personenbezogenen Daten der Überwachung bezieht?',
                                  content: `<p>Es ist egal, wie gering der Teil Ihrer personenbezogenen Daten ist der bei der &Uuml;berwachung des Internets entdeckt wurde. In jedem Fall ist es wichtig, den entsprechenden Anbieter zu kontaktieren und Ihre Kontodaten und andere Log-In Daten zu &auml;ndern, denn Falls ein kleiner Teil Ihrer Daten kompromittiert wurde ist es sehr naheliegend, dass auch weitere Daten davon betroffen sind.</p>
                                    <p>Sie sollten auch Ihre Kreditauskunft &uuml;berpr&uuml;fen, um sicher sein zu k&ouml;nnen, dass Ihnen dort alle aufgef&uuml;hrten Daten bekannt sind.</p>`,
                                },
                                {
                                  title:
                                    'Ist es möglich trotz der engagierten Überwachung des Internets Opfer eines Identitätsdiebstahls zu werden?',
                                  content: `<p>Es gibt kein Instrument, das einen vollst&auml;ndigen Schutz vor Identit&auml;tsdiebstahl gew&auml;hrleistet.<br />Die &Uuml;berwachung des Internets ist trotzdem ein sehr effektives Engagement, um das Risiko nicht nur deutlich zu verringern, sondern auch eine schnelle Behebung eines Vorfalls zu erm&ouml;glichen.<br />Sie verf&uuml;gen &uuml;ber einen Wiederherstellungsdienst, der sie durch einen oftmals schwierigen und komplizierten Prozess hindurchf&uuml;hrt und die finanziellen Auswirkungen verringert.</p>`,
                                },
                                {
                                  title:
                                    'Ist Identitätsschutz jederzeit erreichbar?',
                                  content: `<p>Wir sind 24 Stunden &ndash; 7 Tage die Woche f&uuml;r Sie erreichbar. Von montags bis freitags, 8:00 bis 20:00 Uhr mitteleurop&auml;ischer Zeit erreichen Sie zus&auml;tzlich unser Spezialteam, welches alle Fragen detailliert beantworten kann.</p>`,
                                },
                                {
                                  title:
                                    'Falls ich einem Identitätsdiebstahl zum Opfer falle, ist es sinnvoll eine Betrugswarnung bei der Kreditauskunfteien einzurichten?',
                                  content: `<p>Auf jeden Fall. Sie haben das Recht, Betrugsmeldungen in Ihre Akte aufzunehmen um eine Vorwarnung, dass sie Opfer eines Identit&auml;tsdiebstahls sein k&ouml;nnten, f&uuml;r potenzielle Gl&auml;ubiger und andere zu hinterlegen.</p>
                                    <p>Diese Ma&szlig;nahme erschwert es Betr&uuml;gern &uuml;ber Ihr Verm&ouml;gen zu verf&uuml;gen.</p>`,
                                },
                                {
                                  title: 'Was ist Keylogging?',
                                  content: `<p>Es bezeichnet die Verfolgung und Protokollierung von Tasteneingaben oder das Aufnehmen vom Bildschirm des Benutzers (ein so genannter Screenshot).<br />Diese Aufzeichnung findet verdeckt statt, so dass der Nutzer beim Schreiben von sofort Nachrichten, E-Mails und anderen Informationen nicht merkt, dass diese vertraulichen Informationen an einen Dritten &uuml;bermittelt werden.<br />Diese so genannten Keylogger Softwares K&ouml;nnen durch dieses Verfahren sowohl &uuml;ber die Tastatur getippte Bankdaten, sowie Kennw&ouml;rter und Anmeldedaten verf&uuml;gen.</p>`,
                                },
                                {
                                  title: 'Was ist eine Schadsoftware?',
                                  content: `<p>Eine sch&auml;dliche Software, die von Hackern entwickelt wurde, um auf das System zuzugreifen und personenbezogene Daten zu stehlen.<br />Diese Software greift direkt in das System Ihres Rechners ein, um so an sensible Informationen zu gelangen.</p>`,
                                },
                                {
                                  title:
                                    'Welche Anbieter im Bereich Sicherheitssoftware gibt es?',
                                  content: `<p>Es gibt sehr viele gute Anbieter, die eine Sicherheitssoftware f&uuml;r Ihr Endger&auml;t vermarkten. Derzeit sind die bekanntesten Anbieter Kaspersky, Norton und F-Secure. Es gibt jedoch viele weitere Anbieter die Sicherheitssoftware anbieten. Am besten suchen Sie nach aktuellen Testergebnissen zu Sicherheitssoftware. Regelm&auml;&szlig;ig werden neue Versionen und Software auf den Markt gebracht von den unterschiedlichsten Anbietern. Informieren Sie sich, welche Version und welcher Anbieter Ihre Bed&uuml;rfnisse in Richtung Sicherheitssoftware deckt und vergleichen Sie verschiedenste Produkte, bevor Sie sich eines kaufen.</p>`,
                                },
                                {
                                  title:
                                    'Vor welchen Bedrohungen wird mein Computer oder mein Mobilgerät geschützt?',
                                  content: `<p>Die Schutzfunktionen der Sicherheitssoftwares sind unterschiedlich. Dabei ist die Relevanz des zu behebenden Problems von Bedeutung. Die meisten Antiviren-Programme kommen h&auml;ufig mit einer Firewall gekoppelt mit einem Echtzeit Virenschutz auf den Markt. Zu vielen Basis Programmen kann man sich seinen &bdquo;Rundum-Schutz&ldquo; durch die Erg&auml;nzung eines weiteren Programms zusammenstellen. Einen ann&auml;hernden vollumfassenden Schutz bieten die neuesten Versionen. Diese verf&uuml;gen &uuml;ber einen Schutz vor Malware, welcher die Sch&auml;dlinge identifiziert und dann l&ouml;scht oder blockiert. Des Weiteren bieten viele Softwares einen Schutz f&uuml;r sicheres Online Banking, indem der Nutzer &uuml;ber eine virtuelle Tastatur verf&uuml;gt, wogegen Keylogger keine Chance haben. Viele Anbieter stellen auch eine Kindersicherung bereit, mit der die Eltern Kontrolle &uuml;ber das Web-Verhalten der Kinder haben und ggf. nicht jugendfreie Seiten sperren k&ouml;nnen.</p>`,
                                },
                                {
                                  title:
                                    'Für welche Geräte bietet eine Sicherheitssoftware Schutz?',
                                  content: `<p>Welche Sicherheitssoftware mit welchen Ger&auml;ten kompatibel ist, kommt individuell auf den Anbieter bzw. auf die Version des Programms an. Die meisten Programme sind mit Windows 7 (oder neuer) und Mac OS 10.13 (oder neuer) kompatibel. Auch f&uuml;r Mobilger&auml;te die &uuml;ber das Betriebssystem Android oder iOS laufen gibt es viele Sicherheitssoftwares. Jedoch sollten Sie sich vor dem Kauf einer Software genau informieren, ob die Komptabilit&auml;t mit Ihrem Ger&auml;t &uuml;bereinstimmt.</p>`,
                                },
                                {
                                  title:
                                    'Mit welchen Kosten muss ich bei einem Kauf einer Sicherheitssoftware rechnen?',
                                  content: `<p>Der Kostenfaktor h&auml;ngt von Ihren individuellen Bed&uuml;rfnissen ab. Einige Sicherheitssoftwares stehen frei und kostenlos zum Download zur Verf&uuml;gung, w&auml;hrend andere Sicherheitssoftwares kostenpflichtig angeboten werden.</p>`,
                                },
                                {
                                  title:
                                    'Was bedeutet die Einschätzung meines Risikoniveaus? ',
                                  content: `<ul>
                                  <li><strong>schwach (gr&uuml;n):<br /><br /></strong>Es ist kein Finding in Ihren hinterlegten Daten vorhanden.<br /><br /></li>
                                  <li><strong>mittel (gelb):<br /><br /></strong>Es sind ein bis zwei Findings vorhanden, diese sind jedoch nicht kritisch. Beispielsweise kann dies die Telefonnummer sein.<strong><br /></strong></li>
                                  <li><strong>hoch (rot):<br /><br /></strong>Es wurden mehr als zwei Findings einer eher unkritischen Kategorie ausfindig gemacht oder es sind mehrere Findings im kritischen Bereich wie z.B. die IBAN oder der Personalausweis, vorhanden.</li>
                                  </ul>`,
                                },
                              ]}
                            ></Accordion>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
