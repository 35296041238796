import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

library.add(fab, fas, far, fal);

ReactDOM.render(<App />, document.getElementById("root"));
