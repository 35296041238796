import { API } from './API';
import Config from './config';

class Auth {
  static async login(ssmToken: string): Promise<true | string> {
    return new Promise(async (resolve, reject) => {
      try {
        const iamUrl = Config.getIAMURL();
        const response = await fetch(iamUrl + '/oauth/token', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Api-Key': Config.getApiKey(),
          },
          body: JSON.stringify({
            grant_type: 'jwt',
            scope: 'none',
            client_id: 'sid',
            request_parameters: {
              get: { token: ssmToken },
            },
          }),
        });
        const result = await response.json();

        const token = result.access_token;

        if (token) {
          localStorage.setItem('token', token);
          localStorage.setItem('tokenCreatedAt', Date.now().toString());
          API.setToken(token);
          API.token = token;

          window.history.replaceState(
            {},
            document.title,
            window.location.href.replace(window.location.search, '')
          );
          resolve(true);
        }
        reject('invalidCredentials');
      } catch (e) {
        console.error(e);
        if (e.message.indexOf('User is not verified') > -1)
          return 'notVerified';

        reject('invalidCredentials');
      }
    });
  }

  static logout() {
    localStorage.removeItem('token');
    API.setToken();
    (window as any).location.href = '/';
  }

  static async checkLogin(): Promise<boolean> {
    const storedToken = localStorage.getItem('token');
    if (!API.token && storedToken) API.token = storedToken;

    if (!API.token) return false;

    try {
      await API.get('users/me');
      return true;
    } catch (e) {
      API.setToken();
      return false;
    }
  }

  static isLoggedIn(): boolean {
    const storedToken = localStorage.getItem('token');

    if (!API.token && storedToken) API.token = storedToken;

    return !!API.token;
  }

  static getTokenInfo() {
    if (!API.token) return null;

    const parts = API.token.split('.');
    return JSON.parse(atob(parts[1]));
  }

  static getFKN() {
    const tokenInfo = Auth.getTokenInfo();
    if (!tokenInfo) return null;

    if (tokenInfo.contracts && tokenInfo.contracts.length > 0) {
      return tokenInfo.contracts[0].id;
    } else if (tokenInfo.userInfo && tokenInfo.userInfo._aditoBasicContractId) {
      return tokenInfo.userInfo._aditoBasicContractId;
    }

    return null;
  }
}

export default Auth;
