import React from "react";
import "./LogoutInfo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSignOut } from "@fortawesome/pro-regular-svg-icons";
import Auth from "../../services/auth";

export default function LogoutInfo({
  firstName,
  lastName,
  id,
}: {
  firstName: string | undefined;
  lastName: string | undefined;
  id: number;
}) {
  const createdAt = localStorage.getItem("tokenCreatedAt");

  const remainingTime = Date.now() - +(createdAt || 0);
  if (remainingTime >= 1800000) {
    Auth.logout();
  }

  const [time, setTime] = React.useState(
    29 -
      new Date(remainingTime).getMinutes() +
      ":" +
      (60 - new Date(remainingTime).getSeconds()) +
      " Min"
  );

  setInterval(() => {
    const remainingTime = Date.now() - +(createdAt || 0);
    setTime(
      29 -
        new Date(remainingTime).getMinutes() +
        ":" +
        ("0" + (60 - new Date(remainingTime).getSeconds())).slice(-2) +
        " Min"
    );
  }, 1000);

  if (!createdAt || firstName === undefined || lastName === undefined) {
    return null;
  }

  return (
    <div className="LogoutInfo">
      <h4>{firstName + " " + lastName}</h4>
      <p>Kundennummer: {id}</p>
      <p>
        <FontAwesomeIcon icon={faClock} /> Sie werden abgemeldet in <br />
        {time}
      </p>
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          Auth.logout();
        }}
      >
        <FontAwesomeIcon icon={faSignOut} /> Jetzt ausloggen
      </a>
    </div>
  );
}
