import "./EditTextField.scss";

import {
  faEdit,
  faSave,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import SweetAlert from "sweetalert2";

import IdSchutzManager from "./../../services/idschutz.manager";

export default function EditTextField({
  id,
  value,
  dataKey,
  item,
  onUpdate,
  editUserData,
}: {
  id: string;
  value: any;
  dataKey: any;
  item: any;
  editUserData?: boolean;
  onUpdate: () => {};
}) {
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [currentValue, setCurrentValue] = React.useState(value);
  const element = React.createRef();

  const updateItem = () => {
    if (editUserData) {
      IdSchutzManager.updateUserData({
        [dataKey]: (element.current as HTMLDivElement).getElementsByTagName(
          "input"
        )[0].value,
      })
        .then(() => {
          setIsDisabled(true);
          onUpdate();
          SweetAlert.fire({
            text: "Ihre Daten wurden erfolgreich gespeichert",
            icon: "success",
          });
        })
        .catch((error) => {
          SweetAlert.fire({
            text:
              "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Daten und versuchen es erneut.",
            icon: "error",
          });
        });
    } else {
      let updateObject = IdSchutzManager.getCreateObject(
        dataKey,
        (element.current as HTMLDivElement).getElementsByTagName("input")[0]
          .value
      );
      IdSchutzManager.updateFormData(dataKey, item.id, updateObject)
        .then(() => {
          setIsDisabled(true);
          onUpdate();
          SweetAlert.fire({
            text: "Ihre Daten wurden erfolgreich gespeichert",
            icon: "success",
          });
        })
        .catch((error) => {
          SweetAlert.fire({
            text:
              "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Daten und versuchen es erneut.",
            icon: "error",
          });
        });
    }
  };

  return (
    <TextField
      id={id}
      className={"EditTextField" + (isDisabled ? " disabled" : "")}
      value={currentValue}
      disabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(event) => {
        setCurrentValue(event.target.value);
      }}
      onKeyPress={(e) => {
        if (e.charCode === 13) {
          updateItem();
        }
      }}
      InputProps={{
        ref: element,
        endAdornment: (
          <InputAdornment position="end">
            {!isDisabled && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDisabled(true);
                }}
                edge="end"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            )}
            <IconButton
              onClick={async (e) => {
                e.stopPropagation();
                if (isDisabled) {
                  setIsDisabled(false);
                } else {
                  updateItem();
                }
              }}
              edge="end"
            >
              {(isDisabled && <FontAwesomeIcon icon={faEdit} />) || (
                <FontAwesomeIcon icon={faSave} />
              )}
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                if (editUserData) {
                  SweetAlert.fire({
                    html: "Möchten Sie die Daten wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Abbrechen",
                    reverseButtons: true,
                    confirmButtonText: "Löschen",
                  }).then((data) => {
                    if (data.value) {
                      let deleteObj: any = {
                        [dataKey]: "",
                      };
                      if (dataKey === "nationalIdNumber")
                        deleteObj.nationalIdCountryCode = "";
                      IdSchutzManager.updateUserData(deleteObj).then(() =>
                        onUpdate()
                      );
                    }
                  });
                } else {
                  SweetAlert.fire({
                    html: "Möchten Sie die Daten wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Abbrechen",
                    reverseButtons: true,
                    confirmButtonText: "Löschen",
                  }).then((data) => {
                    if (data.value) {
                      IdSchutzManager.deleteFormData(
                        dataKey,
                        item.id
                      ).then(() => onUpdate());
                    }
                  });
                }
              }}
              edge="end"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
