export interface AlertData {
  date: string;
  value: string;
  type: AlertType;
}

export enum AlertType {
  bankAccount = "bankAccount",
  iban = "iban",
  creditCard = "creditCard",
  email = "email",
  phone = "phone",
  passportNumber = "passportNumber",
  driversLicenseNumber = "driversLicenseNumber",
  firstName = "firstName",
  lastName = "lastName",
  dayOfBirth = "dayOfBirth",
  nationalIdNumber = "nationalIdNumber",
}
