import * as React from "react";
import * as ReactDOM from "react-dom";

export default class Head extends React.Component<{}, {}> {
  render() {
    return ReactDOM.createPortal(
      this.props.children,
      document.getElementsByTagName("head")[0]
    );
  }
}
