import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import React from 'react';

export default function ResponsiveTable({
  title,
  columns,
  data,
  onTableChange,
  options,
}: {
  title: string;
  columns: MUIDataTableColumnDef[];
  data: Array<object | number[] | string[]>;
  onTableChange?: (action: any, tableState: any) => void | undefined;
  options?: MUIDataTableOptions;
}) {
  const selectableRows: "none" | "multiple" | "single" | undefined = "none";

  
  const opt: MUIDataTableOptions = {
    pagination: false,
    sortFilterList: false,
    sort: true,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows,
    textLabels: {
      body: {
        noMatch: "Keine Einträge gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        next: "Nächste Seite",
        previous: "Vorherige Seite",
        rowsPerPage: "Einträge pro Seite:",
        displayRows: "von",
      },
      toolbar: {
        search: "Suche",
        downloadCsv: "CSV herunterladen",
        print: "Drucken",
        viewColumns: "Spalten anzeigen",
        filterTable: "Tabelle filtern",
      },
      filter: {
        all: "Alle",
        title: "FILTER",
        reset: "ZURÜCKSETZEN",
      },
      viewColumns: {
        title: "Spalten einblenden",
        titleAria: "Spalten einblenden/ausblenden",
      },
      selectedRows: {
        text: "Zeile(n) ausgewählt",
        delete: "Löschen",
        deleteAria: "Ausgewählte Zeilen löschen",
      },
    },
    serverSide: onTableChange !== undefined,
    onTableChange,
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{ ...opt, ...options }}
    />
  );
}
