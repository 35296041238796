import * as React from "react";
import { CircularProgress } from "@material-ui/core";

export const AppLoading = () => (
  <div
    style={{
      textAlign: "center",
      padding: "5em",
      backgroundColor: "#ff0000",
      height: "100vh",
      color: "#fff",
      justifyItems: "center",
      alignItems: "center",
      display: "flex",
    }}
  >
    <div
      style={{
        textAlign: "center",
        display: "block",
        width: "100%",
      }}
    >
      {/* <img src="https://dummyimage.com/80x80/000/fff" alt="" /> */}
      <br />
      <CircularProgress
        color="inherit"
        style={{
          marginTop: "4em",
        }}
      />
    </div>
  </div>
);
