import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

import InnerLayout from "./../components/InnerLayout/InnerLayout";
import { Layout } from "./../components/Layout/Layout";

export default function Imprint() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Layout>
        <div className="Imprint">
          <InnerLayout>
            <Grid container spacing={2} style={{ marginBottom: 30 }}>
              <Grid item xs={12}>
                <NavLink to="/" style={{ marginLeft: 20 }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: 4 }}
                  />
                  <span>Zurück zur Übersicht</span>
                </NavLink>
              </Grid>
            </Grid>
            <div className="contentBox">
              <h1>Impressum</h1>
              <p>Dieses Informationsangebot wird bereitgestellt von:</p>
              <p>
                MEHRWERK GmbH <br />
                Am Lenkwerk 5 <br />
                33609 Bielefeld
              </p>
              <p>
                Telefon +49 (0) 521 / 7000 -200 <br />
                Fax +49 (0) 521 / 7000 -220
              </p>
              <p>
                <a href="mailto:s-identitaetsschutz@mehrwerk.de">
                  s-identitaetsschutz@mehrwerk.de
                </a>
              </p>
              <p>
                Gesch&auml;ftsf&uuml;hrer
                <br />
                Frank Hippen, Niels Kokkeel, Max Elsner
                <br />
                <br />
                Registergericht
                <br />
                Bielefeld, HR B 40153
              </p>
              <p>
                USt.-Identifikations-Nr.
                <br />
                DE 274570543
              </p>
              <p>
                Inhaltlich Verantwortlicher gem&auml;&szlig; &sect; 10 <br />
                Absatz 3 MDStV <br />
                Frank Hippen (Anschrift wie oben)
              </p>
              <h4>Haftungsausschluss</h4>
              <p>
                Trotz sorgf&auml;ltiger inhaltlicher Kontrolle &uuml;bernehmen
                wir keine Haftung f&uuml;r die Inhalte externer Links. F&uuml;r
                den Inhalt der verlinkten Seiten sind ausschlie&szlig;lich deren
                Betreiber selbst verantwortlich.
              </p>
              <p>
                Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr
                des Nutzers. Mit der reinen Nutzung der Website des Anbieters
                kommt keinerlei Vertragsverh&auml;ltnis zwischen dem Nutzer und
                dem Anbieter zustande.
              </p>
              <p>
                Rechtswirksamkeit dieses Haftungsausschlusses <br />
                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
                betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
                Teile oder einzelne Formulierungen dieses Textes der geltenden
                Rechtslage nicht, nicht mehr oder nicht vollst&auml;ndig
                entsprechen sollten, bleiben die &uuml;brigen Teile des
                Dokumentes in ihrem Inhalt und ihrer G&uuml;ltigkeit davon
                unber&uuml;hrt.
              </p>
            </div>
          </InnerLayout>
        </div>
      </Layout>
    </div>
  );
}
