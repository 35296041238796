import "./Navigation.scss";

import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  toggleDrawer: any;
}

export default function Navigation(props: Props) {
  return (
    <div className="navigation">
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: 70 }}
        >
          <Grid item style={{ paddingLeft: 8 }}>
            <NavLink to={"/"}>
              <img
                src="assets/sidschutz_logo.png"
                alt=""
                style={{ marginLeft: 15, maxWidth: "220px" }}
              />
            </NavLink>
          </Grid>
          <Grid item>
            <div className="burger">
              <Button onClick={() => props.toggleDrawer()}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
