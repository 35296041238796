import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../services/auth";

export default (props: any) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/logout", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
