import React from "react";

import InnerLayout from "../components/InnerLayout/InnerLayout";
import { Layout } from "../components/Layout/Layout";

export default function Logout() {
  window.scrollTo(0, 0);
  console.log("logout page");
  return (
    <Layout>
      <div className="Logout">
        <InnerLayout>
          <h2>Nicht eingeloggt</h2>
          <p>Sie sind ausgeloggt.</p>
          <p>
            Bitte loggen Sie sich erneut in Ihrem Sparkassenportal ein.
            <br />
            Von dort aus k&ouml;nnen Sie im eingeloggten Zustand erneut auf die
            Seite von Identit&auml;tsschutz gelangen.
          </p>
        </InnerLayout>
      </div>
    </Layout>
  );
}
