import * as React from "react";
import { Alert } from "../../services/alert";
import Navigation from "../Navigation/Navigation";
import Footer from "./../Footer/Footer";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  createMuiTheme,
  ThemeProvider,
  Drawer,
  List,
  Snackbar,
  SnackbarContent,
  MenuItem,
  Divider,
} from "@material-ui/core";
import "./Layout.scss";
import Auth from "../../services/auth";
import Head from "./Head";
import { NavLink } from "react-router-dom";

interface Props {
  title?: string;
  menuItems?: any[];
}

interface State {
  isDrawerOpen: boolean;
  loading: boolean;
  loginError?: any;

  username: string;
  password: string;
  isLoginOpen: boolean;
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff0000",
      main: "#ff0000",
      dark: "#ff0000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#333",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
});

export class Layout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      loading: false,

      username: "",
      password: "",

      isLoginOpen: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleToggleLogin = this.handleToggleLogin.bind(this);
  }

  async componentDidMount() {
    // TODO: set title
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      "Ein Fehler ist aufgetreten",
      "Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers."
    );
  }

  handleToggleLogin() {
    this.setState({
      isLoginOpen: !this.state.isLoginOpen,
    });
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  render() {
    if (!this.state) return;

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {Auth.isLoggedIn() && <meta name="fkn" content={Auth.getFKN()} />}
        </Head>
        <div className="layout">
          <Drawer
            onBackdropClick={this.closeDrawer}
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <List>
              {Auth.isLoggedIn() &&
                !!this.props.menuItems &&
                this.props.menuItems}
              {Auth.isLoggedIn() && !!this.props.menuItems && <Divider />}

              <MenuItem>
                <NavLink to="/agb">AGB</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/datenschutz">Datenschutz</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/impressum">Impressum</NavLink>
              </MenuItem>
            </List>
          </Drawer>
          <Navigation toggleDrawer={this.toggleDrawer} />
          <div className="content-wrapper">{this.props.children}</div>
          <Footer />

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.loginError !== undefined}
            autoHideDuration={6000}
            onClose={() => this.setState({ loginError: undefined })}
            style={{
              backgroundColor: "#D32F2F",
            }}
          >
            <SnackbarContent
              aria-describedby="login-snackbar"
              message={<span id="login-snackbar">{this.state.loginError}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.setState({ loginError: undefined })}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
              style={{
                backgroundColor: "#D32F2F",
              }}
            />
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}
