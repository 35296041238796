import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

import InnerLayout from "./../components/InnerLayout/InnerLayout";
import { Layout } from "./../components/Layout/Layout";

export default function Terms() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Layout>
        <div className="Terms">
          <InnerLayout>
            <Grid container spacing={2} style={{ marginBottom: 30 }}>
              <Grid item xs={12}>
                <NavLink to="/" style={{ marginLeft: 20 }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: 4 }}
                  />
                  <span>Zurück zur Übersicht</span>
                </NavLink>
              </Grid>
            </Grid>
            <div className="contentBox">
              <h1>Allgemeine Geschäfts- und Nutzungs&shy;bedingungen</h1>
              <p>
                <strong>zum Identit&auml;tsschutz</strong>
              </p>
              <p>
                <strong> &sect; 1 Allgemeine Bestimmungen</strong>
              </p>
              <p>
                1. Der Identit&auml;tsschutz ist ein Produkt der Mehrwerk GmbH
                (nachfolgend Mehrwerk), Am Lenkwerk 5, 33609 Bielefeld,
                Deutschland. Diese Bedingungen gelten f&uuml;r alle
                Rechtsbeziehungen und Vertragsverh&auml;ltnisse zwischen Ihnen
                und der Mehrwerk zum Produkt Identit&auml;tsschutz. Abweichende
                Bedingungen des Kunden werden nicht anerkannt, es sei denn,
                ihrer Geltung wurde schriftlich durch Mehrwerk zugestimmt.
              </p>
              <p>
                Der Identit&auml;tsschutz umfasst folgende
                Leistungsbestandteile: Plattformbetrieb f&uuml;r das
                Online-Monitoring-Tool und Servicehotline zur Beratung im Rahmen
                von gefundenen Daten.
              </p>
              <p>
                2. Verantwortlich f&uuml;r die Erbringung der Leistungen ist
                Mehrwerk als der Plattformbetreiber. Zur Leistungserbringung
                bedient sich Mehrwerk weiterer Kooperationspartner.
              </p>
              <p>
                <strong>&sect; 2 Zugang und Vertragsschluss </strong>
              </p>
              <p>
                1. Als Voraussetzung f&uuml;r den Abschluss des Vertrages gilt,
                dass
              </p>
              <ul>
                <li>a. Sie mindestens 18 Jahre alt sind und</li>
                <li>
                  b. Ihr Hauptwohnsitz in der Bundesrepublik Deutschland liegt
                  und
                </li>
                <li>
                  c. Sie einen Vertrag zu einem Konto oder Kartenprodukt mit
                  Ihrer Sparkasse haben, in dem der Identit&auml;tsschutz
                  enthalten ist.
                </li>
              </ul>
              <p>
                2. Mit der Registrierung schlie&szlig;en Sie mit Mehrwerk einen
                unentgeltlichen Nutzungsvertrag &uuml;ber das Produkt
                Identit&auml;tsschutz, welcher die Bedingungen der Nutzung durch
                Sie, sowie unsere Pflichten und Leistungsversprechen regelt. Der
                Vertrag kommt automatisch durch die Zustimmung zur Registrierung
                im Rahmen des Konto oder Kartenproduktes &uuml;ber das
                Onlineportal Ihrer Sparkasse und die entsprechende Weiterleitung
                zum Identit&auml;tsschutz-Portal zustande.
              </p>
              <p>
                <strong>&sect; 3 Vertragslaufzeit und K&uuml;ndigung</strong>
              </p>
              <p>
                Die S-Markt und Mehrwert GmbH &amp; Co. KG (Grenzstra&szlig;e
                21, 06112 Halle/Saale, nachfolgend S-MM) fungiert als
                Dienstleister und hat Mehrwerk als Subdienstleister f&uuml;r die
                Erbringung des Identit&auml;tsschutz beauftragt. Solange diese
                Dienstleistungsbeziehung besteht, haben Sie die M&ouml;glichkeit
                mit einem g&uuml;ltigen Vertrag &uuml;ber ein Konto oder
                Kartenprodukt, welches den Identit&auml;tsschutz beinhaltet,
                diese Leistungen zu nutzen. Sie k&ouml;nnen Ihren
                Nutzungsvertrag zum Identit&auml;tsschutz jederzeit
                k&uuml;ndigen. Mehrwerk und alle zur Dienstleistung
                hinzugef&uuml;gten Kooperationspartner werden dann die
                gespeicherten Daten innerhalb von 90 Tagen nach K&uuml;ndigung
                l&ouml;schen. Sie haben w&auml;hrend der Dauer Ihres Vertrags
                &uuml;ber ein Konto oder Kartenprodukt die M&ouml;glichkeit, die
                Nutzung jederzeit wieder aufzunehmen. Es ist in diesem Fall
                jedoch notwendig, dass Sie alle Daten neu eingeben und sich neu
                registrieren.
              </p>
              <p>
                Mehrwerk kann die Identit&auml;tsschutz-Mitgliedschaft durch
                fristlose K&uuml;ndigung beenden, wenn Sie eine wesentliche
                Vertragsbedingung verletzen oder nicht mehr erf&uuml;llen
                (beispielsweise die Mitgliedschaftsvoraussetzungen oder
                Sorgfaltspflichten oder die Verbote gem&auml;&szlig; Abschnitt
                8, 9 oder 10 dieser AGB), oder wenn eine gesetzliche
                Verpflichtung dazu besteht oder die Vertragsbeziehung zwischen
                S-MM und Mehrwerk endet.
              </p>
              <p>
                <strong>&sect; 4 Inanspruchnahme</strong>
              </p>
              <p>
                Vor Beginn der Nutzung des Identit&auml;tsschutz m&uuml;ssen Sie
                sich online &uuml;ber das Onlineportal Ihrer Sparkasse
                registrieren. Anschlie&szlig;end werden Sie direkt vom
                Onlineportal Ihrer Sparkasse weitergeleitet und k&ouml;nnen die
                Leistungen wie beschrieben nutzen. Zur telefonischen
                Auftragsannahme im Service-Center m&uuml;ssen Sie sich als
                Identit&auml;tsschutz-Kunde durch Nennung Ihrer IBAN
                legitimieren. Ohne korrekte Legitimation wird Mehrwerk im
                Interesse Ihrer eigenen Sicherheit keine Auftr&auml;ge annehmen.
                Telefongespr&auml;che mit dem Identit&auml;tsschutz
                Service-Center k&ouml;nnen aufgezeichnet werden. Diese
                Aufzeichnungen werden verwendet, um die Auftr&auml;ge der Kunden
                entsprechend bearbeiten zu k&ouml;nnen und um die
                Servicequalit&auml;t des Service-Centers laufend zu
                &uuml;berpr&uuml;fen. Sie werden vor Beginn der Aufzeichnung auf
                diese hingewiesen. Eine Aufzeichnung unterbleibt, wenn Sie
                dieser widersprechen.
              </p>
              <p>
                <strong>&sect;</strong> <strong>5 Leistungsumfang </strong>
              </p>
              <p>
                <strong>
                  Userdaten: <br />
                </strong>
                F&uuml;r den Identit&auml;tsschutz wird eine Plattform
                bereitgestellt, welche die Eingabe pers&ouml;nlicher Daten
                erm&ouml;glicht. Das User Interface kann folgende Elemente
                &uuml;berwachen:
              </p>
              <ul>
                <li>max. 1 Nationale Identifikationsnummer (Ausweisnummer)</li>
                <li>max. 10 E-Mail-Adressen</li>
                <li>max. 10 Telefonnummern</li>
                <li>max. 10 Kredit-/Debitkartennummern</li>
                <li>max. 10 Internationale Bankkontonummern</li>
                <li>max. 1 F&uuml;hrerscheinnummer</li>
                <li>max. 1 Passnummer</li>
              </ul>
              <p>
                <br />
                <strong>Data-Screening:</strong>
              </p>
              <p>
                Die Technologie &uuml;berwacht die Aktivit&auml;ten im Internet,
                um zu pr&uuml;fen, ob personenbezogene Daten unerlaubt online
                gehandelt und/oder verkauft werden. &Uuml;ber den
                Identit&auml;tsschutz erteilen Sie den Auftrag zur Suche, der
                von Ihnen definierten Suchdaten und zur Speicherung und
                Verarbeitung Ihrer Daten zu diesem Zweck. Die Technologie
                durchsucht Netzwerke, Chatrooms und Websites weltweit nach
                gestohlenen Identit&auml;tsanmeldeinformationen und findet
                Endbenutzerdaten unabh&auml;ngig von Nationalit&auml;t oder
                Standort. <br />
                Zu jedem Zeitpunkt &uuml;berwacht die Technologie mehrfach
                Websites und Millionen von Datenpunkten und warnt registrierte
                Endbenutzer, wenn ihre pers&ouml;nlichen Daten online gehandelt,
                gefunden, ge- oder verkauft werden. Wenn kompromittierte Daten
                identifiziert werden, werden Bots ausgel&ouml;st, um
                vollst&auml;ndige Identit&auml;tszeichenfolgen zu greifen. Diese
                eindeutigen Daten werden dann gesammelt und gespeichert. Sobald
                eine &Uuml;bereinstimmung mit einem Endnutzer gefunden wurde,
                wird eine Warnung mit Details der &uuml;bereinstimmenden
                Elemente oder Identit&auml;tszeichenfolge gesendet, sodass
                Ma&szlig;nahmen durch den Endnutzer ergriffen werden
                k&ouml;nnen. Die Suche beschr&auml;nkt sich auf die von Ihnen
                eingegebenen Suchdaten. Eine Gew&auml;hr f&uuml;r die
                Vollst&auml;ndigkeit der Suche oder das Auffinden von unbefugt
                abgebildeten oder genutzten Daten im Internet wird nicht
                gegeben.
              </p>
              <p>
                <strong>Monitoring:</strong>
              </p>
              <p>
                Kunden k&ouml;nnen durch das Monitoring pers&ouml;nliche Daten
                im Internet suchen lassen. Dies dient dazu, Daten in
                &ouml;ffentlich zug&auml;nglichen Informationen
                aufzusp&uuml;ren, bei denen dem Kunden evtl. nicht bewusst ist,
                dass diese Daten im Netz &ouml;ffentlich sind. Das Monitoring
                sucht regelm&auml;&szlig;ig nach den hinterlegten
                pers&ouml;nlichen Daten, die im Internet einschlie&szlig;lich
                Deep- und Dark-Web unerlaubt verwendet werden. <br />
                Eine Gew&auml;hrleistung, dass alle im Internet befindlichen
                Daten gefunden werden, kann nicht gegeben werden.
              </p>
              <p>
                <strong>Benachrichtigung:</strong>
              </p>
              <p>
                Alle vom Kunden eingegebenen pers&ouml;nlichen Daten werden im
                Internet mit Hinweis auf Missbrauch gesucht. Die Kunden
                k&ouml;nnen die Suchdaten jederzeit auf der Plattform im
                pers&ouml;nlichen Bereich erfassen und &auml;ndern.
                <br />
                Werden Daten durch die Suche gefunden, werden diese im
                Kunden-Dashboard inklusive Risikoeinstufung angezeigt. Ebenfalls
                wird der Kunde per E-Mail oder SMS benachrichtigt, wenn diese
                Funktion aktiviert wurde.
              </p>
              <p>
                <strong>Suchfunktion:</strong>
              </p>
              <p>
                Die Suche funktioniert durch technische und menschliche
                Intelligenz. Auf &ouml;ffentlich zug&auml;nglichen Webseiten, in
                Adress-Verzeichnissen, Telefonb&uuml;chern und Suchmaschinen im
                Internet, wo pers&ouml;nliche Informationen des Kunden, wie z.
                B. Name, Adresse, E-Mail-Adresse, Telefonnummer zu finden sind,
                wird nach diesen durch den Kunden registrierten Daten gesucht.
                <br />
                Die Suche im Internet erfolgt &uuml;ber ein &bdquo;web
                crawling&ldquo;. Diese Technologie wird genutzt, um
                automatisiert Inhalte der genannten Bereiche im Internet
                auszulesen. Hierbei werden Texte, Links und Strukturen der
                Website ausgewertet und kategorisiert. Alle eingesetzten Crawler
                durchsuchen Texte, Links und Strukturen von Webseiten. Diese
                werden dann ausgewertet und kategorisiert sowie nach
                Sicherheitsrisiken eingestuft. <br />
                Die Suchfunktion der Plattform sucht f&uuml;r den Kunden
                gem&auml;&szlig; deutschem Datenschutzrecht in &ouml;ffentlich
                zug&auml;nglichen Internetbereichen, ob die vom Kunden
                ausgew&auml;hlten Daten, z. B. seine Kreditkartennummer, seine
                E-Mail-Adresse etc. von Dritten ver&ouml;ffentlicht bzw. zur
                Weitergabe angeboten werden. Sollte dies der Fall sein, wird der
                Kunde durch die Plattform dar&uuml;ber informiert. Der Kunde hat
                somit die M&ouml;glichkeit, den Missbrauch seiner Daten im
                Internet zu verhindern.
              </p>
              <p>
                <strong>Telefonischer Service:</strong> <br />
                Die telefonische Hilfe umfasst ein Service-Center, welches dem
                Endnutzer bei der L&ouml;sung eines
                Identit&auml;tsdiebstahls-Ereignisses unterst&uuml;tzt, indem es
                Anleitungen zur L&ouml;schung oder Sicherung seiner Daten
                bereitstellt. Das Service-Center stellt dem Endnutzer
                Informationen zur Verf&uuml;gung, wie das Problem gel&ouml;st
                werden kann, falls m&ouml;glich, einschlie&szlig;lich einer
                zust&auml;ndigen Agentur, die der Nutzer kontaktieren kann, um
                Datenl&ouml;schungen zu beauftragen
              </p>
              <ul>
                <li>Bereitstellung einer deutschen Telefonnummer</li>
                <li>
                  Service-Center-Dienste: Callcenter-Services f&uuml;r den
                  Endnutzer werden in der Zeit von Mo.-Fr. 08:00-18:00 Uhr
                  bereitgestellt.
                </li>
                <li>
                  Datenl&ouml;schung: Grunds&auml;tzlich kann die L&ouml;schung
                  gefundener Daten nicht durchgef&uuml;hrt werden. Daher ist
                  dies kein Leistungsumfang im Sinne dieser AGB.
                  <br />
                  <br />
                  Es wird aber f&uuml;r die Endkunden m&ouml;glich sein, die
                  Datenl&ouml;schung zu beantragen. Eine Unterst&uuml;tzung wird
                  seitens des Service-Centers durchgef&uuml;hrt.
                </li>
              </ul>
              <p>
                <br />
                <strong>Nutzung:</strong>
              </p>
              <p>
                Die Online-&Uuml;berwachung darf ausschlie&szlig;lich f&uuml;r
                private und nicht f&uuml;r kommerzielle oder sonstige Zwecke
                genutzt werden. Eine Gew&auml;hr f&uuml;r die
                Vollst&auml;ndigkeit der Suche oder das Auffinden von unbefugt
                abgebildeten oder genutzten Daten im Internet wird nicht
                gegeben.
              </p>
              <p>
                Hilfreiche Tipps und Infos zum Schutz der Daten <br />
                Im Leistungspaket enthalten sind zudem auf der Plattform
                bereitgestellte Sicherheitstipps. F&uuml;r den Kunden gibt es
                Informationsseiten, auf denen der richtige Umgang mit sensiblen
                Daten im Internet beschrieben wird.
              </p>
              <p>
                <strong>&sect; 6 Sorgfaltspflichten des Kunden</strong>
              </p>
              <p>
                Sie sind verpflichtet, Ihre Profildaten wie Name,
                Zahlungsdetails und Anschrift korrekt anzugeben und stets
                aktuell zu halten. &Auml;nderungen k&ouml;nnen Sie selbst in
                Ihrem Profil vornehmen. Der Identit&auml;tsschutz darf
                ausschlie&szlig;lich f&uuml;r private und nicht f&uuml;r
                kommerzielle oder sonstige Zwecke genutzt werden. Dar&uuml;ber
                hinaus sind Sie verpflichtet, die von uns bekannt gegebene
                Zugangskennung streng geheim zu halten und diese nicht an
                Nichtberechtigte weiterzugeben oder diese Nichtberechtigten
                zug&auml;nglich zu machen. Sie haben die folgenden
                Nutzungsverbote zu beachten: Die Eingabe von Daten Dritter ist
                nicht gestattet. Sie d&uuml;rfen ausschlie&szlig;lich Ihre
                eigenen, pers&ouml;nlichen Daten in das Data-Screening eingeben.
                Unzul&auml;ssig sind dar&uuml;ber hinaus:
              </p>
              <ul>
                <li>
                  Elemente vom Identit&auml;tsschutz f&uuml;r die Verletzung
                  geistigen Eigentums (einschlie&szlig;lich, jedoch nicht
                  beschr&auml;nkt auf Urheberrechts- oder Markenverletzungen und
                  Verletzungen des Namensrechts durch Dom&auml;nennamen),
                  Terrorismus, religi&ouml;sen Fanatismus, Rassismus,
                  Missbrauch, Bedrohung, diffamierende Zwecke, Mobbing,
                  Kinderpornografie oder jegliche anderweitigen rechtlich oder
                  moralisch inakzeptablen Zwecke zu verwenden,
                </li>
                <li>
                  unsere Dienstleistungen, Hosts oder Netzwerke sowie die
                  Nutzungsm&ouml;glichkeit f&uuml;r andere Kunden zu
                  beeintr&auml;chtigen oder dies zu versuchen. Dies
                  schlie&szlig;t, ohne Einschr&auml;nkung, die &Uuml;berflutung
                  von Netzwerken mit E-Mails, den bewussten Versuch der
                  &Uuml;berlastung einer Dienstleistung oder den Versuch, einen
                  Host zum Absturz zu bringen, mit ein,
                </li>
                <li>
                  das Versenden von E-Mails mit irref&uuml;hrenden bzw. falschen
                  Kopfzeilen oder mit Information, die die Herkunft der E-Mail
                  verschleiern oder die unseren Ruf, den Ruf unserer
                  Auftragnehmer oder den Ruf anderer Internetbenutzer
                  sch&auml;digen oder sch&auml;digen k&ouml;nnen,
                </li>
                <li>
                  Verst&ouml;&szlig;e bzw. versuchte Verst&ouml;&szlig;e gegen
                  unsere Netzwerksicherheit oder f&uuml;r Angriffe auf die
                  Netzwerke, Authentifizierungsma&szlig;nahmen, Server oder
                  Ger&auml;te anderer Systeme. Dies schlie&szlig;t den Versuch
                  mit ein, die Benutzerauthentifizierung bzw. die
                  Sicherheitsvorkehrungen beliebiger Hosts, Netzwerke oder
                  Benutzerkonten zu umgehen,
                </li>
                <li>
                  das Versenden von Nachrichten oder Viren, durch die unsere
                  Systeme, die Kommunikationssysteme unserer Unterauftragnehmer
                  oder anderer Kunden bzw. die jedes anderen Dritten
                  gesch&auml;digt oder m&ouml;glicherweise gesch&auml;digt
                  werden,
                </li>
                <li>
                  das Abfangen oder &Uuml;berwachen von Daten, die nicht
                  f&uuml;r Sie bestimmt sind,
                </li>
                <li>
                  das bewusste Eingeben von Daten, die Viren, W&uuml;rmer,
                  Trojaner, Spyware oder anderweitige Schadprogramme enthalten,
                  die dazu gedacht sind, die korrekte Funktionsweise jeglicher
                  Software oder Hardware zu beeintr&auml;chtigen.
                </li>
              </ul>
              <p>
                <strong>&sect; 7 Haftung</strong>
              </p>
              <p>
                Der Plattformbetreiber, ihre Vertreter und
                Erf&uuml;llungsgehilfen sowie die f&uuml;r die Erbringung von
                Leistungen zust&auml;ndigen Kooperationspartner haften f&uuml;r
                Leistungen aus oder im Zusammenhang mit dem
                Identit&auml;tsschutz nach den folgenden Bestimmungen: Die
                Haftung ist bei einfacher Fahrl&auml;ssigkeit auf die Verletzung
                wesentlicher Vertragspflichten und dabei auf den typischen
                vorhersehbaren Schaden begrenzt. Wesentliche Vertragspflichten
                sind solche Verpflichtungen, deren Erf&uuml;llung die
                ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrags
                &uuml;berhaupt erst erm&ouml;glichen und auf deren Einhaltung
                der Vertragspartner regelm&auml;&szlig;ig vertraut und vertrauen
                darf. Der Plattformbetreiber ist stets bem&uuml;ht, alle Angaben
                und Informationen innerhalb ihres Internetauftritts aktuell zu
                halten. In regelm&auml;&szlig;igen Abst&auml;nden werden alle
                Inhalte &uuml;berpr&uuml;ft und ggf. aktualisiert. Trotz
                sorgf&auml;ltiger Kontrolle kann es zu Irrt&uuml;mern bei den
                Angaben kommen. Der Plattformbetreiber &uuml;bernimmt daher
                keine Haftung und gibt auch keine Garantie daf&uuml;r, dass die
                im Rahmen des Internetauftritts dargestellten Inhalte und
                Angaben aktuell, korrekt und vollst&auml;ndig sind. Der
                Plattformbetreiber &uuml;bernimmt keine Gew&auml;hrleistung
                f&uuml;r die vom Kunden in Anspruch genommenen Leistungen der
                Kooperationspartner. Die Haftung von dem Plattformbetreiber
                f&uuml;r Unrichtigkeit, Unvollst&auml;ndigkeit und sonstige
                M&auml;ngel der Leistungen der Kooperationspartner ist
                ausgeschlossen. Es wird jegliche Haftung aufgrund eventuell
                auftretender technischer oder sonstiger St&ouml;rungen
                ausgeschlossen. Die Haftung f&uuml;r unverschuldete
                beh&ouml;rdliche Ma&szlig;nahmen, Arbeitsk&auml;mpfe,
                h&ouml;here Gewalt, Naturkatastrophen und zuf&auml;llige
                Sch&auml;den ist ausgeschlossen. S&auml;mtliche
                Haftungsausschl&uuml;sse gelten nicht, wenn dem
                Plattformbetreiber Vorsatz oder grobe Fahrl&auml;ssigkeit
                vorzuwerfen ist, im Fall der Verletzung von Leben, K&ouml;rper
                oder Gesundheit oder im Fall einer Haftung nach dem
                Produkthaftungsgesetz.
              </p>
              <p>
                <strong>
                  &sect; 8 &Auml;nderungen der Vertragsbedingungen
                </strong>
              </p>
              <p>
                Der Plattformbetreiber beh&auml;lt sich vor, die
                Vertragsbedingungen einseitig zu &auml;ndern. Der
                Plattformbetreiber informiert Sie &uuml;ber alle &Auml;nderungen
                der Allgemeinen Gesch&auml;ftsbedingungen mindestens 30 Tage vor
                Wirksamwerden der &Auml;nderung. In diesem Fall k&ouml;nnen Sie
                den Nutzungsvertrag innerhalb einer Frist von 30 Tagen nach
                Erhalt der Mitteilung k&uuml;ndigen. &Auml;u&szlig;ern Sie sich
                innerhalb dieser Frist nicht, wird die &Auml;nderung wirksam.
                Sie werden im Rahmen der &Auml;nderungsmitteilung &uuml;ber den
                Beginn dieser Frist, die Bedeutung Ihres Schweigens auf eine
                solche Mitteilung und &uuml;ber Ihr K&uuml;ndigungsrecht
                ausdr&uuml;cklich hingewiesen. Bitte beachten Sie in diesem
                Zusammenhang auch Ihr vorstehend unter Abschnitt 3 beschriebenes
                allgemeines K&uuml;ndigungsrecht.
              </p>
              <p>
                <strong>&sect; 9 Allgemeines</strong>
              </p>
              <p>
                Die Leistungen zum Identit&auml;tsschutz werden Ihnen unter dem
                Vorbehalt der Verf&uuml;gbarkeit angeboten. Der
                Plattformbetreiber bem&uuml;ht sich, abzusichern, dass Ihnen die
                Leistungen, f&uuml;r die der Plattformbetreiber die
                Verantwortung tr&auml;gt, ohne St&ouml;rungen zur Verf&uuml;gung
                stehen. Notwendige Wartungsarbeiten, Weiterentwicklung und/oder
                andere St&ouml;rungen k&ouml;nnen die Nutzungsm&ouml;glichkeiten
                einschr&auml;nken und/oder zeitweise unterbrechen. Unter
                Umst&auml;nden kann es zu Datenverlusten kommen. Sollten
                einzelne Bestimmungen des Vertrags unwirksam sein oder werden,
                so bleiben die &uuml;brigen Bestimmungen davon unber&uuml;hrt.
                Anstelle der unwirksamen Bestimmung tritt diejenige wirksame
                Regelung, die dem wirtschaftlich Gewollten am N&auml;chsten
                kommt. Sie erhalten die Angaben zum Vertragsschluss sowie die
                geltenden AGB und Datenschutzerkl&auml;rungen per E-Mail. Der
                Abschluss des Identit&auml;tsschutz-Vertrags und die
                Kommunikation w&auml;hrend der Laufzeit des Vertrags erfolgt in
                deutscher Sprache. OS-Plattform &ndash; Onlinestreitbeilegung
                Die EU-Kommission stellt gem&auml;&szlig; Art. 14 Abs. 1 der
                EU-Verordnung Nr. 524/2013 (ODR VO) eine interaktive Website
                (OS-Plattform) bereit, die eine au&szlig;ergerichtliche
                Beilegung von Streitigkeiten aus Online-Rechtsgesch&auml;ften
                erm&ouml;glicht. Die OS-Plattform der EU-Kommission finden Sie
                unter diesem Link:{" "}
                <a href="http://ec.europa.eu/consumers/odr/" target="_blank">
                  http://ec.europa.eu/consumers/odr/
                </a>{" "}
                Hinweis nach &sect; 36 VSBG: Wir sind zur Teilnahme an einem
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
              </p>
              <p>
                <strong>&sect; 10 Daten&uuml;bertragung, Datenschutz</strong>
              </p>
              <p>
                Die im Rahmen des vorliegenden Vertrages erhobenen
                personenbezogenen Daten werden elektronisch erfasst und
                verarbeitet, soweit dies im Rahmen des Bundesdatenschutzgesetzes
                und der DSGVO zul&auml;ssig ist.
              </p>
              <p>
                Die &Uuml;bermittlung personenbezogener Daten erfolgt lediglich
                zum Zweck der Erf&uuml;llung der vertragsgegenst&auml;ndlichen
                Leistungen an weitere Kooperationspartner, soweit dies
                hierf&uuml;r erforderlich ist.
              </p>
              <p>
                Die genauen Informationen zur Datenverarbeitung entnehmen Sie
                bitte den separaten Datenschutzbestimmungen.
              </p>
              <p>
                <strong>&sect; 11 Anzuwendendes Recht</strong>
              </p>
              <p>F&uuml;r diesen Vertrag gilt deutsches Recht.</p>
              <p>
                <strong>&sect; 12 Haftungsausschluss</strong>
              </p>
              <p>
                1. F&uuml;r Sch&auml;den, die an anderen Rechtsg&uuml;tern als
                dem Leben, K&ouml;rper oder der Gesundheit entstehen, ist die
                Haftung ausgeschlossen, soweit die Sch&auml;den nicht auf
                vors&auml;tzlichem oder grob fahrl&auml;ssigem Verhalten des
                Plattformbetreibers, eines von deren gesetzlichen Vertretern
                oder einer von deren Erf&uuml;llungsgehilfen beruhen und das
                Verhalten auch keine Verletzung von vertragswesentlichen
                Pflichten ist. Wesentliche Vertragspflichten sind solche
                Pflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e
                Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst
                erm&ouml;glicht und auf deren Einhaltung Sie
                regelm&auml;&szlig;ig vertrauen d&uuml;rfen.
              </p>
              <p>
                2. Die vorgenannten Haftungsausschl&uuml;sse und
                Beschr&auml;nkungen gelten au&szlig;erdem nicht im Fall der
                &Uuml;bernahme ausdr&uuml;cklicher Garantien durch den
                Plattformbetreiber sowie bei Anspr&uuml;chen wegen fehlender
                zugesicherter Eigenschaften.
              </p>
              <p>
                <strong>&sect; 13 Rechtswahl und Gerichtsstand</strong>
              </p>
              <p>
                Der Vertrag zwischen Ihnen und dem Plattformbetreiber &uuml;ber
                die Mitgliedschaft und Ihr erster Kontakt mit dem
                Plattformbetreiber unterliegen deutschem Recht unter Ausschluss
                des Kollisionsrechts und unter Ausschluss des UN-Kaufrechts
                (Wiener CISG-&Uuml;bereinkommen). Streitigkeiten im Zusammenhang
                mit diesen Vertr&auml;gen unterliegen ausschlie&szlig;lich der
                Rechtsprechung deutscher Gerichte.
              </p>
              <p>
                <strong>Gesetzliche Widerrufsbelehrung </strong>
              </p>
              <p>
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
                Gr&uuml;nden in Textform (z. B. Brief, Fax, E-Mail) den Vertrag
                zu widerrufen. Die Widerrufsfrist betr&auml;gt vierzehn Tage ab
                dem Tag nach Erhalt dieser Belehrung in Textform, jedoch nicht
                vor Vertragsschluss. Um Ihr Widerrufsrecht auszu&uuml;ben,
                m&uuml;ssen Sie an den Plattformbetreiber mittels einer
                eindeutigen Erkl&auml;rung (z.B. ein mit der Post versandter
                Brief, Telefax oder E-Mail) &uuml;ber Ihren Entschluss den
                Vertrag zu widerrufen, informieren. Zur Wahrung der
                Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber
                die Aus&uuml;bung des Widerrufsrechts vor Ablauf der
                Widerrufsfrist absenden.
              </p>
              <p>Stand: Februar 2021</p>
            </div>
          </InnerLayout>
        </div>
      </Layout>
    </div>
  );
}
