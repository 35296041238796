import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

import InnerLayout from "./../components/InnerLayout/InnerLayout";
import { Layout } from "./../components/Layout/Layout";

export default function Privacy() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Layout>
        <div className="Privacy">
          <InnerLayout>
            <Grid container spacing={2} style={{ marginBottom: 30 }}>
              <Grid item xs={12}>
                <NavLink to="/" style={{ marginLeft: 20 }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: 4 }}
                  />
                  <span>Zurück zur Übersicht</span>
                </NavLink>
              </Grid>
            </Grid>
            <div className="contentBox">
              <h1>Datenschutz&shy;bestimmungen</h1>
              <h2>
                Wichtiger Hinweis zu Identit&auml;tsschutz (Stand: Februar 2021)
              </h2>
              <p>
                Der Identit&auml;tsschutz ist ein Produkt der Mehrwerk GmbH
                (nachfolgend Mehrwerk), Am Lenkwerk 5, 33609 Bielefeld,
                Deutschland. Bei der Umsetzung des Kundenportals handelt
                Mehrwerk im Auftrag der Sparkasse.
              </p>
              <p>
                Der Identit&auml;tsschutz umfasst folgende
                Leistungsbestandteile: Plattformbetrieb f&uuml;r das
                Online-Monitoring-Tool und Servicehotline zur Beratung im Rahmen
                von gefundenen Daten.
              </p>
              <h2>
                1. Information &uuml;ber die Erhebung personenbezogener Daten
              </h2>
              <p>
                (1) Der Schutz Ihrer pers&ouml;nlichen Daten ist Mehrwerk
                besonders wichtig. Diese Datenschutzerkl&auml;rung kl&auml;rt
                Sie &uuml;ber die Art, den Umfang und Zweck der Verarbeitung von
                personenbezogenen Daten (nachfolgend kurz &bdquo;Daten&ldquo;)
                innerhalb unseres Angebotes des Identit&auml;tsschutz.
              </p>
              <p>
                Die Verarbeitung personenbezogener Daten, beispielsweise des
                Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer
                betroffenen Person, erfolgt stets im Einklang mit der
                Datenschutz-Grundverordnung und in &Uuml;bereinstimmung mit den
                f&uuml;r Mehrwerk geltenden landesspezifischen
                Datenschutzbestimmungen. Mittels dieser
                Datenschutzerkl&auml;rung m&ouml;chte unser Unternehmen die
                &Ouml;ffentlichkeit &uuml;ber Art, Umfang und Zweck der von uns
                erhobenen, genutzten und verarbeiteten personenbezogenen Daten
                informieren. Ferner werden betroffene Personen mittels dieser
                Datenschutzerkl&auml;rung &uuml;ber die ihnen zustehenden Rechte
                aufgekl&auml;rt.
              </p>
              <p>
                Mehrwerk hat zahlreiche technische und organisatorische
                Ma&szlig;nahmen umgesetzt, um einen m&ouml;glichst
                l&uuml;ckenlosen Schutz der &uuml;ber diese Internetseite
                verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch
                k&ouml;nnen internetbasierte Daten&uuml;bertragungen
                grunds&auml;tzlich Sicherheitsl&uuml;cken aufweisen, sodass ein
                absoluter Schutz nicht gew&auml;hrleistet werden kann. Aus
                diesem Grund steht es jeder betroffenen Person frei, keine
                personenbezogenen Daten einzugeben und zu speichern. Die
                Leistung ist dann seitens Mehrwerk / der Sparkasse nicht
                geschuldet.
              </p>
              <p>
                (2) Gegenstand des Datenschutzes sind personenbezogene Daten.
                Diese sind alle Informationen, die sich auf eine bestimmte oder
                bestimmbare nat&uuml;rliche Person beziehen. Hierunter fallen z.
                B. Angaben wie Name, Post-Adresse, E-Mail-Adresse oder
                Telefonnummer, ggf. aber auch Nutzungsdaten, wie Ihre
                IP-Adresse.
                <br />
                In dieser Datenschutzerkl&auml;rung teilt Mehrwerk Ihnen mit,
                wie mit den personenbezogenen Daten umgegangen wird, die
                Mehrwerk im Rahmen der Kundenbeziehung von Ihnen erh&auml;lt und
                im Rahmen des Identit&auml;tsschutzes verarbeitet. Hierzu
                geh&ouml;ren auch alle Leistungen, sowie Ihre Korrespondenz mit
                Mehrwerk. Die Leistungen erfolgen:
              </p>
              <ul>
                <li>online &uuml;ber das Online Monitoring von Mehrwerk,</li>
                <li>telefonisch beim Mehrwerk Service-Center oder</li>
                <li>
                  per Mail an{" "}
                  <a href="mailto:s-identitaetsschutz@mehrwerk.de">
                    s-identitaetsschutz@mehrwerk.de
                  </a>
                </li>
              </ul>
              <p>
                Selbstverst&auml;ndlich beachtet Mehrwerk die anwendbaren
                gesetzlichen Bestimmungen des Datenschutzes. Ihre Daten werden
                durch digitale Sicherheitssysteme verschl&uuml;sselt und an
                Mehrwerk &uuml;bertragen. Die Webseiten sind durch technische
                Ma&szlig;nahmen gegen Besch&auml;digungen, Zerst&ouml;rung oder
                unberechtigten Zugriff gesch&uuml;tzt.
              </p>
              <p>
                (3) Die Datenschutzerkl&auml;rung von Mehrwerk beruht auf den
                Begrifflichkeiten, die durch den Europ&auml;ischen Richtlinien-
                und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                (DS-GVO) verwendet wurden. Unsere Datenschutzerkl&auml;rung soll
                sowohl f&uuml;r die &Ouml;ffentlichkeit als auch f&uuml;r unsere
                Kunden und Gesch&auml;ftspartner einfach lesbar und
                verst&auml;ndlich sein. Um dies zu gew&auml;hrleisten,
                m&ouml;chten wir vorab die verwendeten Begrifflichkeiten
                erl&auml;utern.
              </p>
              <p>
                Wir verwenden in dieser Datenschutzerkl&auml;rung unter anderem
                die folgenden Begriffe:
              </p>
              <p>(a) personenbezogene Daten</p>
              <p>
                Personenbezogene Daten sind alle Informationen, die sich auf
                eine identifizierte oder identifizierbare nat&uuml;rliche Person
                (im Folgenden &bdquo;betroffene Person&ldquo;) beziehen. Als
                identifizierbar wird eine nat&uuml;rliche Person angesehen, die
                direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,
                zu einer Online-Kennung oder zu einem oder mehreren besonderen
                Merkmalen, die Ausdruck der physischen, physiologischen,
                genetischen, psychischen, wirtschaftlichen, kulturellen oder
                sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind,
                identifiziert werden kann.
              </p>
              <p>(b) betroffene Person</p>
              <p>
                Betroffene Person ist jede identifizierte oder identifizierbare
                nat&uuml;rliche Person, deren personenbezogene Daten von dem
                f&uuml;r die Verarbeitung Verantwortlichen verarbeitet werden.
              </p>
              <p>(c) Verarbeitung</p>
              <p>
                Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
                Verfahren ausgef&uuml;hrte Vorgang oder jede solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
                das Erheben, das Erfassen, die Organisation, das Ordnen, die
                Speicherung, die Anpassung oder Ver&auml;nderung, das Auslesen,
                das Abfragen, die Verwendung, die Offenlegung durch
                &Uuml;bermittlung, Verbreitung oder eine andere Form der
                Bereitstellung, den Abgleich oder die Verkn&uuml;pfung, die
                Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung.
              </p>
              <p>(d) Einschr&auml;nkung der Verarbeitung</p>
              <p>
                Einschr&auml;nkung der Verarbeitung ist die Markierung
                gespeicherter personenbezogener Daten mit dem Ziel, ihre
                k&uuml;nftige Verarbeitung einzuschr&auml;nken.
              </p>
              <p>(e) Profiling</p>
              <p>
                Profiling ist jede Art der automatisierten Verarbeitung
                personenbezogener Daten, die darin besteht, dass diese
                personenbezogenen Daten verwendet werden, um bestimmte
                pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche
                Person beziehen, zu bewerten, insbesondere, um Aspekte
                bez&uuml;glich Arbeitsleistung, wirtschaftlicher Lage,
                Gesundheit, pers&ouml;nlicher Vorlieben, Interessen,
                Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
                dieser nat&uuml;rlichen Person zu analysieren oder
                vorherzusagen.
              </p>
              <p>(f) Pseudonymisierung</p>
              <p>
                Pseudonymisierung ist die Verarbeitung personenbezogener Daten
                in einer Weise, auf welche die personenbezogenen Daten ohne
                Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer
                spezifischen betroffenen Person zugeordnet werden k&ouml;nnen,
                sofern diese zus&auml;tzlichen Informationen gesondert
                aufbewahrt werden und technischen und organisatorischen
                Ma&szlig;nahmen unterliegen, die gew&auml;hrleisten, dass die
                personen&not;bezogenen Daten nicht einer identifizierten oder
                identifizierbaren nat&uuml;rlichen Person zugewiesen werden.
              </p>
              <p>
                (g) Verantwortlicher oder f&uuml;r die Verarbeitung
                Verantwortlicher
              </p>
              <p>
                Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher
                ist die nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von
                personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel
                dieser Verarbeitung durch das Unionsrecht oder das Recht der
                Mitgliedstaaten vorgegeben, so kann der Verantwortliche
                beziehungsweise k&ouml;nnen die bestimmten Kriterien seiner
                Benennung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten vorgesehen werden.
              </p>
              <p>(h) Auftragsverarbeiter</p>
              <p>
                Auftragsverarbeiter ist eine nat&uuml;rliche oder juristische
                Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die
                personenbezogene Daten im Auftrag des Verantwortlichen
                verarbeitet.
              </p>
              <p>(i) Empf&auml;nger</p>
              <p>
                Empf&auml;nger ist eine nat&uuml;rliche oder juristische Person,
                Beh&ouml;rde, Einrichtung oder andere Stelle, der
                personenbezogene Daten offengelegt werden, unabh&auml;ngig
                davon, ob es sich bei ihr um einen Dritten handelt oder nicht.
                Beh&ouml;rden, die im Rahmen eines bestimmten
                Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten m&ouml;glicherweise personenbezogene Daten
                erhalten, gelten jedoch nicht als Empf&auml;nger.
              </p>
              <p>(j) Dritter</p>
              <p>
                Dritter ist eine nat&uuml;rliche oder juristische Person,
                Beh&ouml;rde, Einrichtung oder andere Stelle au&szlig;er der
                betroffenen Person, dem Verantwortlichen, dem
                Auftragsverarbeiter und den Personen, die unter der
                unmittelbaren Verantwortung des Verantwortlichen oder des
                Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
                verarbeiten.
              </p>
              <p>(k) Einwilligung</p>
              <p>
                Einwilligung ist jede von der betroffenen Person freiwillig
                f&uuml;r den bestimmten Fall in informierter Weise und
                unmissverst&auml;ndlich abgegebene Willensbekundung in Form
                einer Erkl&auml;rung oder einer sonstigen eindeutigen
                best&auml;tigenden Handlung, mit der die betroffene Person zu
                verstehen gibt, dass sie mit der Verarbeitung der sie
                betreffenden personenbezogenen Daten einverstanden ist.
              </p>
              <h2>2. Zweck der Datenverarbeitung</h2>
              <p>
                Mehrwerk und Dritte zur Erbringung von Dienstleistungen
                Beauftragte, speichern Ihre Daten um die geschuldeten Leistungen
                des Identit&auml;tsschutzes zu erbringen. Dazu geh&ouml;ren der
                Plattformbetrieb f&uuml;r das Online-Monitoring-Tool und die
                Servicehotline zur Beratung im Rahmen von gefundenen Daten.
              </p>
              <p>
                Die Leistungserbringung umfasst die Speicherung und das
                Monitoring (&Uuml;berwachen) folgender Userdaten:
              </p>
              <ul>
                <li>
                  max. 1 Nationale Identifikationsnummer
                  (Sozialversicherungsnummer)
                </li>
                <li>max. 10 E-Mail-Adressen</li>
                <li>max. 10 Telefonnummern</li>
                <li>max. 10 Kredit-/Debitkartennummern</li>
                <li>max. 10 Internationale Bankkontonummern</li>
                <li>max. 1 F&uuml;hrerscheinnummer</li>
                <li>max. 1 Passnummer</li>
              </ul>
              <p>
                Im &sect;5 der Allgemeinen Gesch&auml;fts- und
                Nutzungsbedingungen zum Identit&auml;tsschutz ist die Art und
                der Umfang der Leistungserbringung und die Verarbeitung der
                gespeicherten Daten beschrieben.
              </p>
              <p>
                F&uuml;r die Beratung werden ebenfalls die Anrede, der Name und
                Vorname, sowie eine E-Mail-Adresse zur Kommunikation
                gespeichert.
              </p>
              <h2>3. Ihre Rechte</h2>
              <p>
                (1) Sie haben gegen&uuml;ber der Sparkasse folgende Rechte
                hinsichtlich der Sie betreffenden personenbezogenen Daten:
              </p>
              <ul>
                <li>Recht auf Best&auml;tigung</li>
                <li>Recht auf Auskunft,</li>
                <li>Recht auf Berichtigung</li>
                <li>Recht auf L&ouml;schung,</li>
                <li>Recht auf Einschr&auml;nkung der Verarbeitung,</li>
                <li>Recht auf Daten&uuml;bertragbarkeit.</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>
                  Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
                </li>
              </ul>
              <h4>Recht auf Best&auml;tigung</h4>
              <p>
                Jede betroffene Person hat das vom Europ&auml;ischen
                Richtlinien- und Verordnungsgeber einger&auml;umte Recht, von
                dem f&uuml;r die Verarbeitung Verantwortlichen eine
                Best&auml;tigung dar&uuml;ber zu verlangen, ob die betreffenden
                personenbezogene Daten verarbeitet werden. M&ouml;chte eine
                betroffene Person dieses Best&auml;tigungsrecht in Anspruch
                nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des
                f&uuml;r die Verarbeitung Verantwortlichen wenden.
              </p>
              <h4>Recht auf Auskunft</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, jederzeit von dem f&uuml;r
                die Verarbeitung Verantwortlichen unentgeltliche Auskunft
                &uuml;ber die zu seiner Person gespeicherten personenbezogenen
                Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der
                Europ&auml;ische Richtlinien- und Verordnungsgeber der
                betroffenen Person Auskunft &uuml;ber folgende Informationen
                zugestanden:
              </p>
              <ul>
                <li>die Verarbeitungszwecke</li>
                <li>
                  die Kategorien personenbezogener Daten, die verarbeitet werden
                </li>
                <li>
                  die Empf&auml;nger oder Kategorien von Empf&auml;ngern,
                  gegen&uuml;ber denen die personenbezogenen Daten offengelegt
                  worden sind oder noch offengelegt werden, insbesondere bei
                  Empf&auml;ngern in Drittl&auml;ndern oder bei internationalen
                  Organisationen
                </li>
                <li>
                  falls m&ouml;glich die geplante Dauer, f&uuml;r die die
                  personenbezogenen Daten gespeichert werden, oder, falls dies
                  nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung
                  dieser Dauer
                </li>
                <li>
                  das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung
                  der sie betreffenden personenbezogenen Daten oder auf
                  Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen
                  oder eines Widerspruchsrechts gegen diese Verarbeitung
                </li>
                <li>
                  das Bestehen eines Beschwerderechts bei einer
                  Aufsichtsbeh&ouml;rde
                </li>
                <li>
                  wenn die personenbezogenen Daten nicht bei der betroffenen
                  Person erhoben werden: alle verf&uuml;gbaren Informationen
                  &uuml;ber die Herkunft der Daten
                </li>
                <li>
                  das Bestehen einer automatisierten Entscheidungsfindung
                  einschlie&szlig;lich Profiling gem&auml;&szlig; Artikel 22
                  Abs.1 und 4 DS-GVO und &mdash; zumindest in diesen F&auml;llen
                  &mdash; aussagekr&auml;ftige Informationen &uuml;ber die
                  involvierte Logik sowie die Tragweite und die angestrebten
                  Auswirkungen einer derartigen Verarbeitung f&uuml;r die
                  betroffene Person
                </li>
              </ul>
              <p>
                Ferner steht der betroffenen Person ein Auskunftsrecht
                dar&uuml;ber zu, ob personenbezogene Daten an ein Drittland oder
                an eine internationale Organisation &uuml;bermittelt wurden.
                Sofern dies der Fall ist, so steht der betroffenen Person im
                &Uuml;brigen das Recht zu, Auskunft &uuml;ber die geeigneten
                Garantien im Zusammenhang mit der &Uuml;bermittlung zu erhalten.
              </p>
              <p>
                M&ouml;chte eine betroffene Person dieses Auskunftsrecht in
                Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen
                wenden.
              </p>
              <h4>Recht auf Berichtigung</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, die unverz&uuml;gliche
                Berichtigung sie betreffender unrichtiger personenbezogener
                Daten zu verlangen. Ferner steht der betroffenen Person das
                Recht zu, unter Ber&uuml;cksichtigung der Zwecke der
                Verarbeitung, die Vervollst&auml;ndigung unvollst&auml;ndiger
                personenbezogener Daten &mdash; auch mittels einer
                erg&auml;nzenden Erkl&auml;rung &mdash; zu verlangen.
              </p>
              <p>
                M&ouml;chte eine betroffene Person dieses Berichtigungsrecht in
                Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen
                wenden.
              </p>
              <h4>Recht auf L&ouml;schung (Recht auf Vergessen werden)</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, von dem Verantwortlichen
                zu verlangen, dass die sie betreffenden personenbezogenen Daten
                unverz&uuml;glich gel&ouml;scht werden, sofern einer der
                folgenden Gr&uuml;nde zutrifft und soweit die Verarbeitung nicht
                erforderlich ist:
              </p>
              <ul>
                <li>
                  Die personenbezogenen Daten wurden f&uuml;r solche Zwecke
                  erhoben oder auf sonstige Weise verarbeitet, f&uuml;r welche
                  sie nicht mehr notwendig sind.
                </li>
                <li>
                  Die betroffene Person widerruft ihre Einwilligung, auf die
                  sich die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe
                  a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO st&uuml;tzte
                  und es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r
                  die Verarbeitung.
                </li>
                <li>
                  Die betroffene Person legt gem&auml;&szlig; Art. 21 Abs. 1
                  DS-GVO Widerspruch gegen die Verarbeitung ein und es liegen
                  keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die
                  Verarbeitung vor, oder die betroffene Person legt
                  gem&auml;&szlig; Art. 21 Abs. 2 DS-GVO Widerspruch gegen die
                  Verarbeitung ein. &bull; Die personenbezogenen Daten wurden
                  unrechtm&auml;&szlig;ig verarbeitet.
                </li>
                <li>
                  Die L&ouml;schung der personenbezogenen Daten ist zur
                  Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
                  Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich,
                  dem der Verantwortliche unterliegt.
                </li>
                <li>
                  Die personenbezogenen Daten wurden in Bezug auf angebotene
                  Dienste der Informationsgesellschaft gem&auml;&szlig; Art. 8
                  Abs. 1 DS-GVO erhoben.
                </li>
              </ul>
              <p>
                Sofern einer der oben genannten Gr&uuml;nde zutrifft und eine
                betroffene Person die L&ouml;schung von personenbezogenen Daten,
                die bei Mehrwerk gespeichert sind, veranlassen m&ouml;chte, kann
                sie sich hierzu jederzeit an einen Mitarbeiter des f&uuml;r die
                Verarbeitung Verantwortlichen wenden. Der Mitarbeiter von
                Mehrwerk wird veranlassen, dass dem L&ouml;schverlangen
                unverz&uuml;glich nachgekommen wird.
              </p>
              <p>
                Wurden die personenbezogenen Daten &ouml;ffentlich gemacht und
                ist der Verantwortliche gem&auml;&szlig; Art. 17 Abs. 1 DS-GVO
                zur L&ouml;schung der personenbezogenen Daten verpflichtet, so
                trifft die Sparkasse unter Ber&uuml;cksichtigung der
                verf&uuml;gbaren Technologie und der Implementierungskosten
                angemessene Ma&szlig;nahmen, auch technischer Art, um andere
                f&uuml;r die Datenverarbeitung Verantwortliche, welche die
                ver&ouml;ffentlichten personenbezogenen Daten verarbeiten,
                dar&uuml;ber in Kenntnis zu setzen, dass die betroffene Person
                von diesen anderen f&uuml;r die Datenverarbeitung
                Verantwortlichen die L&ouml;schung s&auml;mtlicher Links zu
                diesen personenbezogenen Daten oder von Kopien oder
                Replikationen dieser personenbezogenen Daten verlangt hat,
                soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter
                von Mehrwerk wird im Einzelfall das Notwendige veranlassen.
              </p>
              <h4>Recht auf Einschr&auml;nkung der Verarbeitung</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, von dem Verantwortlichen
                die Einschr&auml;nkung der Verarbeitung zu verlangen, wenn eine
                der folgenden Voraussetzungen gegeben ist:
              </p>
              <ul>
                <li>
                  Die Richtigkeit der personenbezogenen Daten wird von der
                  betroffenen Person bestritten und zwar f&uuml;r eine Dauer,
                  die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit
                  der personenbezogenen Daten zu &uuml;berpr&uuml;fen.
                </li>
                <li>
                  Die Verarbeitung ist unrechtm&auml;&szlig;ig, die betroffene
                  Person lehnt die L&ouml;schung der personenbezogenen Daten ab
                  und verlangt stattdessen die Einschr&auml;nkung der Nutzung
                  der personenbezogenen Daten.
                </li>
                <li>
                  Der Verantwortliche ben&ouml;tigt die personenbezogenen Daten
                  f&uuml;r die Zwecke der Verarbeitung nicht l&auml;nger, die
                  betroffene Person ben&ouml;tigt sie jedoch zur Geltendmachung,
                  Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
                </li>
                <li>
                  Die betroffene Person hat Widerspruch gegen die Verarbeitung
                  gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht
                  fest, ob die berechtigten Gr&uuml;nde des Verantwortlichen
                  gegen&uuml;ber denen der betroffenen Person &uuml;berwiegen.
                </li>
              </ul>
              <p>
                Sofern eine der oben genannten Voraussetzungen gegeben ist und
                eine betroffene Person die Einschr&auml;nkung von
                personenbezogenen Daten, die bei Mehrwerk gespeichert sind,
                verlangt, kann sie sich hierzu jederzeit an einen Mitarbeiter
                des f&uuml;r die Verarbeitung Verantwortlichen wenden. Der
                Mitarbeiter von Mehrwerk wird die Einschr&auml;nkung der
                Verarbeitung veranlassen.
              </p>
              <h4>Recht auf Daten&uuml;bertragbarkeit</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, die sie betreffenden
                personenbezogenen Daten, welche durch die betroffene Person
                einem Verantwortlichen bereitgestellt wurden, in einem
                strukturierten, g&auml;ngigen und maschinenlesbaren Format zu
                erhalten. Sie hat au&szlig;erdem das Recht, diese Daten einem
                anderen Verantwortlichen ohne Behinderung durch den
                Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
                wurden, zu &uuml;bermitteln, sofern die Verarbeitung auf der
                Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe a DS-GVO
                oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag
                gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die
                Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern
                die Verarbeitung nicht f&uuml;r die Wahrnehmung einer Aufgabe
                erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder
                in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, welche dem
                Verantwortlichen &uuml;bertragen wurde.
              </p>
              <p>
                Ferner hat die betroffene Person bei der Aus&uuml;bung ihres
                Rechts auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20
                Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen
                Daten direkt von einem Verantwortlichen an einen anderen
                Verantwortlichen &uuml;bermittelt werden, soweit dies technisch
                machbar ist und sofern hiervon nicht die Rechte und Freiheiten
                anderer Personen beeintr&auml;chtigt werden.
              </p>
              <p>
                Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit kann
                sich die betroffene Person jederzeit an die Sparkasse oder
                Mehrwerk wenden.
              </p>
              <h4>Recht auf Widerspruch</h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, aus Gr&uuml;nden, die sich
                aus ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung sie betreffender personenbezogener Daten, die
                aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt,
                Widerspruch einzulegen.
              </p>
              <p>
                Mehrwerk verarbeitet die personenbezogenen Daten im Falle des
                Widerspruchs nicht mehr, es sei denn, wir k&ouml;nnen zwingende
                schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
                nachweisen, die den Interessen, Rechten und Freiheiten der
                betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient
                der Geltendmachung, Aus&uuml;bung oder Verteidigung von
                Rechtsanspr&uuml;chen.
              </p>
              <p>
                Zudem hat die betroffene Person das Recht, aus Gr&uuml;nden, die
                sich aus ihrer besonderen Situation ergeben, gegen die sie
                betreffende Verarbeitung personenbezogener Daten, die bei
                Mehrwerk zu wissenschaftlichen oder historischen
                Forschungszwecken oder zu statistischen Zwecken gem&auml;&szlig;
                Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei
                denn, eine solche Verarbeitung ist zur Erf&uuml;llung einer im
                &ouml;ffentlichen Interesse liegenden Aufgabe erforderlich.
              </p>
              <p>
                Zur Aus&uuml;bung des Rechts auf Widerspruch kann sich die
                betroffene Person direkt an jeden Mitarbeiter von Mehrwerk oder
                an die Sparkasse wenden. Der betroffenen Person steht es ferner
                frei, im Zusammenhang mit der Nutzung von Diensten der
                Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG,
                ihr Widerspruchsrecht mittels automatisierter Verfahren
                auszu&uuml;ben, bei denen technische Spezifikationen verwendet
                werden.
              </p>
              <h4>
                Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
              </h4>
              <p>
                Jede von der Verarbeitung personenbezogener Daten betroffene
                Person hat das vom Europ&auml;ischen Richtlinien- und
                Verordnungsgeber gew&auml;hrte Recht, eine Einwilligung zur
                Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
              </p>
              <p>
                M&ouml;chte die betroffene Person ihr Recht auf Widerruf einer
                Einwilligung geltend machen, kann sie sich hierzu jederzeit an
                einen Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen
                wenden.
              </p>
              <p>
                Folge des Widerrufs ist, dass die Plattform nicht genutzt werden
                kann, da die Speicherung der Daten die Voraussetzung f&uuml;r
                das Screening der Daten ist.
              </p>
              <p>
                Sie haben zudem das Recht, sich bei einer
                Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber die Verarbeitung
                Ihrer personenbezogenen Daten durch Mehrwerk oder der Sparkasse
                zu beschweren. Die zust&auml;ndige Aufsichtsbeh&ouml;rde finden
                Sie hier:
              </p>
              <p>
                Der Landesbeauftragte f&uuml;r den Datenschutz und die
                Informationsfreiheit Nordrhein-Westfalen
              </p>
              <p>
                Kavalleriestrasse 2-4 <br />
                40213 D&uuml;sseldorf <br />
                <a href="https://www.ldi.nrw.de/" target="_blank">
                  https://www.ldi.nrw.de/
                </a>
              </p>
              <h2>
                4. Name und Anschrift des f&uuml;r die Verarbeitung
                Verantwortliche
              </h2>
              <p>
                Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
                sonstiger in den Mitgliedstaaten der Europ&auml;ischen Union
                geltenden Datenschutzgesetze und anderer Bestimmungen mit
                datenschutzrechtlichem Charakter ist:
              </p>
              <p>
                Verantwortlicher gem&auml;&szlig; Art. 4 Abs. 7 der DS-GVO ist
                die Mehrwerk GmbH, Am Lenkwerk 5, 33609 Bielefeld.
              </p>
              <p>
                Jede betroffene Person kann sich jederzeit bei allen Fragen und
                Anregungen zum Datenschutz direkt an den Datenschutzbeauftragten
                von Mehrwerk wenden:
              </p>
              <p>
                Datenschutzbeauftragter der Mehrwerk GmbH
                <br />
                Am Lenkwerk 5<br />
                33609 Bielefeld
              </p>
              <p>
                Tel.: +49 521 7000-0
                <br />
                <a href="mailto:datenschutz@mehrwerk.de">
                  datenschutz@mehrwerk.de
                </a>
              </p>
              <p>
                Falls die Sparkasse f&uuml;r einzelne Funktionen des Angebots
                auf beauftragte Dienstleister zur&uuml;ckgreifen oder Ihre Daten
                f&uuml;r werbliche Zwecke nutzen m&ouml;chte, werden Sie
                untenstehend im Detail &uuml;ber die jeweiligen Vorg&auml;nge
                informiert. Dabei werden auch die festgelegten Kriterien der
                Speicherdauer genannt.
              </p>
              <h2>5. Cookies</h2>
              <p>
                Mehrwerk verwendet im Rahmen des Identit&auml;tsschutz Cookies.
                Cookies sind Textdateien, welche &uuml;ber einen Internetbrowser
                auf einem Computersystem abgelegt und gespeichert werden.
              </p>
              <p>
                Zahlreiche Internetseiten und Server verwenden Cookies. Viele
                Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist
                eine eindeutige Kennung des Cookies. Sie besteht aus einer
                Zeichenfolge, durch welche Internetseiten und Server dem
                konkreten Internetbrowser zugeordnet werden k&ouml;nnen, in dem
                das Cookie gespeichert wurde. Dies erm&ouml;glicht es den
                besuchten Internetseiten und Servern, den individuellen Browser
                der betroffenen Person von anderen Internetbrowsern, die andere
                Cookies enthalten, zu unterscheiden. Ein bestimmter
                Internetbrowser kann &uuml;ber die eindeutige Cookie-ID
                wiedererkannt und identifiziert werden.
              </p>
              <p>
                Durch den Einsatz von Cookies kann Mehrwerk den Nutzern dieser
                Internetseite nutzerfreundlichere Services bereitstellen, die
                ohne die Cookie-Setzung nicht m&ouml;glich w&auml;ren.
              </p>
              <p>
                Mittels eines Cookies k&ouml;nnen die Informationen und Angebote
                auf unserer Internetseite im Sinne des Benutzers optimiert
                werden. Cookies erm&ouml;glichen uns, wie bereits erw&auml;hnt,
                die Benutzer unserer Internetseite wiederzuerkennen. Zweck
                dieser Wiedererkennung ist es, den Nutzern die Verwendung
                unserer Internetseite zu erleichtern. Der Benutzer einer
                Internetseite, die Cookies verwendet, muss beispielsweise nicht
                bei jedem Besuch der Internetseite erneut seine Zugangsdaten
                eingeben, weil dies von der Internetseite und dem auf dem
                Computersystem des Benutzers abgelegten Cookie &uuml;bernommen
                wird. Ein weiteres Beispiel ist das Cookie eines Warenkorbes im
                Online-Shop. Der Online-Shop merkt sich die Artikel, die ein
                Kunde in den virtuellen Warenkorb gelegt hat, &uuml;ber ein
                Cookie.
              </p>
              <p>
                Die betroffene Person kann die Setzung von Cookies durch unsere
                Internetseite jederzeit mittels einer entsprechenden Einstellung
                des genutzten Internetbrowsers verhindern und damit der Setzung
                von Cookies dauerhaft widersprechen. Ferner k&ouml;nnen bereits
                gesetzte Cookies jederzeit &uuml;ber einen Internetbrowser oder
                andere Softwareprogramme gel&ouml;scht werden. Dies ist in allen
                g&auml;ngigen Internetbrowsern m&ouml;glich. Deaktiviert die
                betroffene Person die Setzung von Cookies in dem genutzten
                Internetbrowser, sind unter Umst&auml;nden nicht alle Funktionen
                unserer Internetseite vollumf&auml;nglich nutzbar.
              </p>
              <h2>6. Erfassung von allgemeinen Daten und Informationen</h2>
              <p>
                Mehrwerk erfasst mit jedem Aufruf der Internetseite durch eine
                betroffene Person oder ein automatisiertes System eine Reihe von
                allgemeinen Daten und Informationen. Diese allgemeinen Daten und
                Informationen werden in den Logfiles des Servers gespeichert.
                Erfasst werden k&ouml;nnen die (1) verwendeten Browsertypen und
                Versionen, (2) das vom zugreifenden System verwendete
                Betriebssystem, (3) die Internetseite, von welcher ein
                zugreifendes System auf unsere Internetseite gelangt (sogenannte
                Referrer), (4) die Unterwebseiten, welche &uuml;ber ein
                zugreifendes System auf unserer Internetseite angesteuert
                werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die
                Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse),
                (7) der Internet-Service-Provider des zugreifenden Systems und
                (8) sonstige &auml;hnliche Daten und Informationen, die der
                Gefahrenabwehr im Falle von Angriffen auf unsere
                informationstechnologischen Systeme dienen.
              </p>
              <p>
                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht
                Mehrwerk keine R&uuml;ckschl&uuml;sse auf die betroffene Person.
                Diese Informationen werden vielmehr ben&ouml;tigt, um (1) die
                Inhalte unserer Internetseite korrekt auszuliefern, (2) die
                Inhalte unserer Internetseite sowie die Werbung f&uuml;r diese
                zu optimieren, (3) die dauerhafte Funktionsf&auml;higkeit
                unserer informationstechnologischen Systeme und der Technik
                unserer Internetseite zu gew&auml;hrleisten sowie (4) um
                Strafverfolgungsbeh&ouml;rden im Falle eines Cyberangriffes die
                zur Strafverfolgung notwendigen Informationen bereitzustellen.
              </p>
              <p>
                Diese anonym erhobenen Daten und Informationen werden durch
                Mehrwerk daher einerseits statistisch und ferner mit dem Ziel
                ausgewertet, den Datenschutz und die Datensicherheit in unserem
                Unternehmen zu erh&ouml;hen, um letztlich ein optimales
                Schutzniveau f&uuml;r die von uns verarbeiteten
                personenbezogenen Daten sicherzustellen. Die anonymen Daten der
                Server-Logfiles werden getrennt von allen durch eine betroffene
                Person angegebenen personenbezogenen Daten gespeichert.
              </p>
              <h2>7. Weiterer Umfang der Datenerhebung und -speicherung</h2>
              <p>
                Einige der Daten stellen Sie selbst bei der Nutzung des
                Services, per Telefon im Rahmen eines telefonischen Supports
                gegen&uuml;ber Mehrwerk Mitarbeitern oder Drittpartnern zur
                Abwicklung des Services direkt zur Verf&uuml;gung. Sie
                entscheiden, welche Daten Mehrwerk von Ihnen erhebt und
                speichert. Es steht Ihnen ferner frei, Mehrwerk Ihre Daten zur
                Verf&uuml;gung zu stellen. Jedoch werden Sie nicht in der Lage
                sein, die Leistungen zu nutzen, falls Sie sich dazu entscheiden,
                die zu diesem Zweck erforderlichen Daten nicht zur
                Verf&uuml;gung zu stellen. <br />
                Mehrwerk wird Ihre personenbezogenen Daten ausschlie&szlig;lich
                im rechtlich zul&auml;ssigen Rahmen nutzen. In der Regel werden
                Ihre Daten im folgenden Rahmen genutzt:
              </p>
              <ul>
                <li>
                  Wenn dies erforderlich ist, um die Leistungen aus dem Vertrag
                  zu erbringen welchen Mehrwerk mit Ihnen schlie&szlig;t bzw.
                  geschlossen hat.
                </li>
                <li>
                  Wenn Mehrwerk (bzw. eine dritte Partei) ein berechtigtes
                  Interesse daran hat und Ihr Interesse und Ihre Rechte an den
                  Daten nicht schwerer wiegen als das Interesse von Mehrwerk an
                  der Verarbeitung personenbezogener Daten (&bdquo;berechtigtes
                  Interesse&ldquo;).
                </li>
                <li>
                  Um gesetzliche und regulatorische Verpflichtungen zu
                  erf&uuml;llen.
                </li>
                <li>
                  In den F&auml;llen, in denen Sie dazu eingewilligt haben.
                </li>
              </ul>
              <p>
                <span style={{ textDecoration: "underline" }}>
                  {" "}
                  Wie Ihre Daten genutzt werden
                </span>
                <br />
                Der Umfang der gesammelten Daten unterliegt dem jeweiligen
                Service, den Sie nutzen bzw. f&uuml;r den Sie sich registrieren.
                Ferner auschlaggebend ist welche Pr&auml;ferenzen Sie
                hinterlegen. Die gespeicherten Daten enthalten
                regelm&auml;&szlig;ig folgende Angaben:
              </p>
              <p>
                Name und Kontaktinformationen, die notwendig sind, um Ihre
                Identit&auml;t zu authentifizieren, damit Sie den jeweiligen
                Service erhalten. Gegebenenfalls werden diese Daten zur
                Koordinierung mit anderen Partnern oder betroffenen Dritten zu
                Erbringung des t&auml;glichen Service und auch zur
                t&auml;glichen Betreuung verwendet. Ohne diese Daten ist es
                Mehrwerk nicht m&ouml;glich, den Service Ihnen gegen&uuml;ber zu
                erbringen bzw. Sie hierf&uuml;r anzumelden und zu
                identifizieren. Des Weiteren sind diese Daten erforderlich, um
                eine erneute Anmeldung zu einem Service vorzubeugen, wenn dem
                Nutzer zuvor aufgrund einer Verletzung der AGB (wie
                beispielsweise bei einem Missbrauch des Service oder bei einer
                betr&uuml;gerischen Handlung) gek&uuml;ndigt wurde.
              </p>
              <ul>
                <li>
                  Die Art der Daten, die Mehrwerk erhebt, umfassen den Vornamen,
                  Nachnamen, Geburtsdatum, Anschrift, E-Mail-Adresse,
                  Telefon-/Mobilfunknummer und vergleichbare Kontaktdaten.
                </li>
                <li>
                  T&auml;gliche Betreuung Ihrer Mitgliedschaft beinhaltet die
                  Kommunikation &uuml;ber Alarm-Information, Erinnerungen im
                  Zusammenhang mit dem jeweiligen Service, Verl&auml;ngerungs-
                  und Beendigungsbenachrichtigungen und
                  Wartungsprobleme/-vorf&auml;lle.
                </li>
              </ul>
              <p>
                Anonymisierte und aggregierte Daten - Anonymisierung ist der
                Prozess der Umwandlung von personenbezogenen Daten in
                anonymisierte Daten, sodass Sie oder eine andere Person nicht
                mehr identifizierbar sind. Aggregierte Daten sind im
                Wesentlichen zusammengefasste Daten, die in der Regel keinen
                Personenbezug haben und folglich Ihre Identit&auml;t nicht
                offenbaren. <br />
                Zus&auml;tzlich zu der oben beschriebenen Nutzung Ihrer Daten
                wird Mehrwerk anonymisierte und aggregierte Daten verarbeiten,
                um die Qualit&auml;t des Services zu verbessern, neue
                Funktionen, Dienstleistungen und Produkte zu entwickeln und
                f&uuml;r Recherchen im Allgemeinen (aggregierte Nutzungsdaten
                zur Verbesserung der User Experience, durchschnittliche
                Verweildauer, etc.). Da diese Informationen keinen Personenbezug
                mehr enthalten, ist Mehrwerk berechtigt, diese Daten an andere
                Unternehmen zu &uuml;bermitteln. Dazu geh&ouml;ren z.B. Dritte
                und Partner.
              </p>
              <h2>8. Weitergabe Ihrer Daten zur Leistungserbringung</h2>
              <p>
                Mit diesem Absatz informieren wir Sie dar&uuml;ber, dass Ihre
                personenbezogenen Daten zur Erbringung der geschuldeten Leistung
                an CSIdentity Corporation (nachfolgend CSID), (CSIdentity
                Corporation, 1501 South Mopac Expressway, Suite 200, Austin, TX
                78746, USA) weitergegeben werden d&uuml;rfen. F&uuml;r die
                Weitergabe der Daten gilt im Weiteren die
                Datenschutzerkl&auml;rung von CSIdentity, die von Zeit zu Zeit
                ge&auml;ndert werden kann.
              </p>
              <p>
                Ihre Daten werden nur unter Beachtung der gesetzlichen
                datenschutzrechtlichen Anforderungen &uuml;bermittelt.
              </p>
              <p>
                Die Verarbeitung Ihrer personenbezogenen Daten von CSID erfolgt
                vor allem auf Servern, die sich in Gro&szlig;britannien
                befinden. Allerdings kann ein Teil der Verarbeitung Ihrer
                personenbezogenen Daten (auch durch einige unserer externen
                Unterauftragsverarbeiter) auf Servern erfolgen, die sich in den
                USA oder einer anderen Rechtsordnung befinden. CSID ergreift
                angemessene Ma&szlig;nahmen, die gem&auml;&szlig; den lokalen
                Datenschutzgesetzen erforderlich sind, um die personenbezogenen
                Daten zu sch&uuml;tzen, was bei &Uuml;bertragungen Ihrer Daten
                an Dritte (und soweit relevant) den Abschluss vertraglicher
                Vereinbarungen mit Dritten zum Schutz Ihrer personenbezogenen
                Daten in der von der EU-Kommission genehmigten Form oder im
                Einklang mit Angemessenheitsentscheidungen der EU-Kommission
                (einschlie&szlig;lich des Rahmens des Datenschutzschilds)
                umfasst.
              </p>
              <h2>
                9. Weitergabe Ihrer Daten zur Einhaltung rechtlicher
                Bestimmungen
              </h2>
              <p>
                Zus&auml;tzlich beh&auml;lt sich Mehrwerk das Recht vor, auf
                Ihre Informationen zuzugreifen und diese preiszugeben, um
                rechtliche Bestimmungen und legitimierte Anfragen wie von
                Beh&ouml;rden oder Exekutivorganen einzuhalten.
              </p>
              <h2>10. Einsatz von Analyticstool</h2>
              <p>
                Wir nutzen den Webanalysedienst Amplitude der Amplitude, Inc.,
                501 2nd Street, Suite 100 San Francisco, CA 94107, um
                Nutzerzugriffe auszuwerten.
              </p>
              <p>
                Die mit den Technologien erhobenen Daten werden ohne die
                gesondert erteilte Zustimmung des Betroffenen nicht dazu
                benutzt, den Nutzer pers&ouml;nlich zu identifizieren und nicht
                mit personenbezogenen Daten &uuml;ber den Tr&auml;ger des
                Pseudonyms zusammengef&uuml;hrt. Die erhobenen Informationen
                werden auf einem Server in den USA gespeichert.
              </p>
              <p>
                Sie k&ouml;nnen die Datenverarbeitung verhindern, indem Sie
                keine Cookies zulassen. Die Datenschutzerkl&auml;rung von
                Amplitude finden Sie hier:{" "}
                <a href="https://amplitude.com/privacy" target="blank">
                  https://amplitude.com/privacy
                </a>
              </p>
              <h2>11. Weitere wichtige Informationen</h2>
              <p>
                <span style={{ textDecoration: "underline" }}>
                  Aktualisierungen / Updates zu dieser Datenschutzerkl&auml;rung
                </span>
              </p>
              <p>
                Es ist m&ouml;glich, dass Mehrwerk oder die Sparkasse diese
                Datenschutzerkl&auml;rung aktualisieren, um notwendige
                &Auml;nderungen der Leistung wiederzugeben. Bei wesentlichen
                &Auml;nderungen dieser Datenschutzerkl&auml;rung, welche die Art
                und Weise der Erhebung und Nutzung beeinflussen, werden Sie
                davon in Kenntnis gesetzt, indem diese auf dieser Webseite
                ver&ouml;ffentlicht werden oder Ihnen diese direkt &uuml;ber
                eine Benachrichtigung zukommen lassen oder mittels einer
                Ank&uuml;ndigung der &Auml;nderungen vor dem Zeitpunkt ihres
                Wirksamwerdens.
              </p>
              <h2>12. Dauer der Speicherung</h2>
              <p>
                Mehrwerk speichert Ihre Daten f&uuml;r die in Ziffer 2
                beschriebenen Zwecke, solange Sie die Leistung nutzen. Nach
                Beendigung Ihres Vertrages und/ oder Nutzung des Services,
                werden Ihre Daten solange gespeichert, wie dies zur Einhaltung
                rechtlicher Vorschriften, zur Schlichtung von Streitigkeiten und
                f&uuml;r zus&auml;tzliche Zwecke beruhend auf den
                Entscheidungen, die Sie get&auml;tigt haben, erforderlich ist.
                Daten, die Sie bei Nutzung der Leistung bereitgestellt haben,
                werden im Rahmen der gesetzlichen Anforderungen zur Vorhaltung
                m&ouml;glicher Betriebspr&uuml;fungen bis maximal 10 Jahre nach
                K&uuml;ndigung des Vertrages gespeichert. Daten, die nicht
                f&uuml;r die oben genannten Zwecke relevant sind, werden 90 Tage
                nach der Beendigung des Vertrages/der Nutzung der Leistung
                gel&ouml;scht.
              </p>
            </div>
          </InnerLayout>
        </div>
      </Layout>
    </div>
  );
}
