import * as React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Error404 } from './features/Error404';
import { Alert } from './services/alert';
import PrivateRoute from './components/PrivateRoute';
import { Home } from './features/Home/Home';
import Auth from './services/auth';
import { AppLoading } from './components/AppLoading';
import ScrollToTop from './components/ScrollToTop';
import queryString from 'query-string';
import Terms from './features/Terms';
import Imprint from './features/Imprint';
import Privacy from './features/Privacy';
import Logout from './features/Logout';

interface State {
  loading: boolean;
  paramsToken?: string;
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    const paramsToken = queryString.parse(window.location.search).token;

    this.state = {
      loading: true,
      paramsToken: paramsToken ? paramsToken.toString() : undefined,
    };
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  async componentDidMount() {
    if (this.state.paramsToken) {
      await Auth.login(this.state.paramsToken);

      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) return <AppLoading />;

    return (
      <div>
        <BrowserRouter basename="/">
          <ScrollToTop />

          <Switch>
            <PrivateRoute exact path="/" component={Home} />

            <Route exact path="/agb" component={Terms} />
            <Route exact path="/impressum" component={Imprint} />
            <Route exact path="/datenschutz" component={Privacy} />
            <Route exact path="/logout" component={Logout} />

            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
