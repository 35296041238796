import React from "react";
import "./EditDataAccordion.scss";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Button,
  InputAdornment,
  Select,
  MenuItem,
  Collapse,
  Grid,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import IdSchutzManager from "./../../services/idschutz.manager";
import EditTextField from "./../EditTextField/EditTextField";
import SweetAlert from "sweetalert2";

export default function EditDataAccordion({
  title,
  dataKey,
  data,
  onUpdate,
}: {
  title: string;
  dataKey: string;
  data: any;
  onUpdate: () => {};
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [expandedMore, setExpandedMore] = React.useState(false);
  const createRef = React.createRef<any>();

  const countryCodeMap = {
    "49": "DEU",
    "31": "NLD",
    "32": "BEL",
    "43": "AUT",
    "33": "FRA",
    "352": "LUX",
    "423": "LIE",
    "41": "CHE",
  };

  const [phoneCode, setPhoneCode] = React.useState(49);

  const createItem = () => {
    const element = createRef.current as HTMLInputElement;
    IdSchutzManager.createFormData(
      dataKey as any,
      IdSchutzManager.getCreateObject(
        dataKey,
        (dataKey === "phoneNumbers" ? "00" + phoneCode : "") + element.value
      )
    )
      .then(() => {
        element.value = "";
        onUpdate();
        setExpandedMore(true);
        SweetAlert.fire({
          text: "Ihre Daten wurden erfolgreich gespeichert",
          icon: "success",
        });
      })
      .catch((error) => {
        SweetAlert.fire({
          text:
            "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Daten und versuchen es erneut.",
          icon: "error",
        });
      });
  };

  return (
    <div className="EditDataAccordion">
      <ExpansionPanel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        elevation={0}
      >
        <ExpansionPanelSummary>
          <h4
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.length < IdSchutzManager.getMaximumForInputType(dataKey) && (
              <div className="addItem"> </div>
            )}
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
            <span style={{ color: "#ccc", marginLeft: 10 }}>
              (
              {data &&
                data.length +
                  "/" +
                  IdSchutzManager.getMaximumForInputType(dataKey)}
              )
            </span>
          </h4>
          {data && data.length > 0 && (
            <EditTextField
              dataKey={dataKey}
              id="field0Item"
              item={data[0]}
              value={data[0].number || data[0].iban || data[0].email}
              onUpdate={() => onUpdate()}
            />
          )}
          {data && data.length > 1 && (
            <div>
              <Collapse in={expandedMore}>
                {data.slice(1, data.length).map((item: any, index: number) => (
                  <EditTextField
                    dataKey={dataKey}
                    id={"field" + (index + 1) + "Item"}
                    item={item}
                    value={item.number || item.iban || item.email}
                    onUpdate={() => onUpdate()}
                  />
                ))}
              </Collapse>
              <div
                className="showMore"
                onClick={(event) => {
                  event.stopPropagation();
                  setExpandedMore(!expandedMore);
                }}
              >
                {(!expandedMore && "mehr") || "weniger"} anzeigen
              </div>
            </div>
          )}
        </ExpansionPanelSummary>
        {data.length < IdSchutzManager.getMaximumForInputType(dataKey) && (
          <ExpansionPanelDetails>
            <div className="contentBox contentBox--background">
              <Grid container>
                <Grid item xs={6}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        IdSchutzManager.getLabel(dataKey, true) + " hinzufügen",
                    }}
                  ></span>
                </Grid>
                <Grid item xs={6}>
                  {(dataKey === "phoneNumbers" && (
                    <TextField
                      id="fieldCreateItem"
                      style={{ width: "100%", background: "#fff" }}
                      inputProps={{ ref: createRef }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              value={phoneCode}
                              onChange={(e: any) =>
                                setPhoneCode(e.target.value)
                              }
                            >
                              {Object.entries(countryCodeMap).map(
                                ([key, value]) => (
                                  <MenuItem value={key}>
                                    +{key + " " + value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          createItem();
                        }
                      }}
                    />
                  )) || (
                    <TextField
                      id="fieldCreateItem"
                      style={{ width: "100%", background: "#fff" }}
                      inputProps={{ ref: createRef }}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          createItem();
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 12,
                    }}
                  >
                    <Button
                      onClick={() => {
                        setExpanded(false);
                        (createRef.current as any).value = "";
                      }}
                      variant="text"
                      color="primary"
                    >
                      <span>Abbrechen</span>
                    </Button>
                    <Button
                      onClick={() => createItem()}
                      variant="contained"
                      color="primary"
                    >
                      <span>Speichern</span>
                      <FontAwesomeIcon icon={faArrowCircleRight} />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {(dataKey === "phoneNumbers" ||
                    dataKey === "emailAddresses") && (
                    <div
                      style={{
                        marginTop: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginRight: 12, fontSize: "2em" }}
                      />
                      <b>
                        <small>
                          Die von Ihnen zuerst hinterlegte
                          {dataKey === "emailAddresses"
                            ? " E-Mail-Adresse "
                            : " Telefonnummer "}
                          wird automatisch für alle Benachrichtigungen vom
                          Identitätsschutz z.B. Findings verwendet.
                        </small>
                      </b>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </div>
  );
}
