import React from "react";
import "./EditUserDataAccordion.scss";
import { TextField, Button, Grid, Select, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/pro-regular-svg-icons";
import IdSchutzManager from "../../services/idschutz.manager";
import EditTextField from "../EditTextField/EditTextField";
import SweetAlert from "sweetalert2";

export default function EditUserDataAccordion({
  title,
  dataKey,
  data,
  onUpdate,
}: {
  title: string;
  dataKey: string;
  data: any;
  onUpdate: () => {};
}) {
  const createRef = React.createRef();

  const createItem = () => {
    const element = createRef.current as HTMLInputElement;
    const createObj: any = { [dataKey]: element.value };
    if (dataKey === "nationalIdNumber")
      createObj.nationalIdCountryCode = currentCountryCode;

    IdSchutzManager.updateUserData(createObj)
      .then(() => {
        element.value = "";
        onUpdate();
        SweetAlert.fire({
          text: "Ihre Daten wurden erfolgreich gespeichert",
          icon: "success",
        });
      })
      .catch((error) => {
        SweetAlert.fire({
          text:
            "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Daten und versuchen es erneut.",
          icon: "error",
        });
      });
  };
  const countryCodes = {
    AT: "Österreich",
    BE: "Belgien",
    BG: "Bulgarien",
    CY: "Zypern",
    CZ: "Tschechien",
    DK: "Dänemark",
    EE: "Estland",
    FI: "Finnland",
    FR: "Frankreich",
    DE: "Deutschland",
    GR: "Griechenland",
    HU: "Ungarn",
    IE: "Irland",
    IT: "Italien",
    LV: "Lettland",
    LT: "Litauen",
    LU: "Luxenburg",
    MT: "Malta",
    NL: "Niederlande",
    PL: "Polen",
    PT: "Portugal",
    RO: "Rumänien",
    SK: "Slowakei",
    SI: "Slovenien",
    ES: "Spanien",
    SE: "Schweden",
    GB: "Großbritannien",
    HR: "Kroatien",
    LI: "Lichtenstein",
    CH: "Schweiz",
  };
  const [currentCountryCode, setCurrentCountryCode] = React.useState("DE");

  return (
    <div className="EditUserDataAccordion">
      <div className="contentBox">
        <h4
          style={{
            display: "flex",
            alignItems: "center",
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h4>
        {!data && (
          <Grid container>
            {dataKey === "nationalIdNumber" && (
              <Grid item xs={6}>
                Ausstellungsland
              </Grid>
            )}
            {dataKey === "nationalIdNumber" && (
              <Grid item xs={6}>
                <Select
                  value={currentCountryCode}
                  onChange={(e: any) => setCurrentCountryCode(e.target.value)}
                >
                  {Object.entries(countryCodes).map(([code, value]) => (
                    <MenuItem value={code}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={6}>
              <span
                dangerouslySetInnerHTML={{ __html: title + " hinzufügen" }}
              ></span>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="fieldCreateItem"
                style={{ width: "100%", background: "#fff" }}
                inputProps={{ ref: createRef }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    createItem();
                  }
                }}
              />
              <div style={{ textAlign: "right", marginTop: 12 }}>
                <Button
                  onClick={() => createItem()}
                  variant="contained"
                  color="primary"
                >
                  <span>Speichern</span>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
        {data && (
          <EditTextField
            dataKey={dataKey}
            id="field0Item"
            item={data}
            value={data}
            editUserData={true}
            onUpdate={() => onUpdate()}
          />
        )}
      </div>
    </div>
  );
}
